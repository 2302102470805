
import { makeApiRequest, generateSymbol, parseFullSymbol, getCandleData } from './helpers.js';
import { subscribeOnStream, unsubscribeFromStream } from './streaming.js';

const lastBarsCache = new Map();

const configurationData = {
    // Represents the resolutions for bars supported by your datafeed
    // supported_resolutions: ['1D', '1W', '1M'],
    supported_resolutions: ['1', '5','15','30','45','60','120','180','240'],
    // The `exchanges` arguments are used for the `searchSymbols` method if a user selects the exchange
    exchanges: [
        { value: 'NSE', name: 'NSE', desc: 'NSE'},
        { value: 'NFO', name: 'NFO', desc: 'NFO'},
        { value: 'MCX', name: 'MCX', desc: 'MCX'},
    ],
    // The `symbols_types` arguments are used for the `searchSymbols` method if a user selects this symbol type
    symbols_types: [
        { name: 'NSE', value: 'NSE'},
        { name: 'NFO', value: 'NFO'},
        { name: 'MCX', value: 'MCX'},
        { name: 'CDS', value: 'CDS'},

    ]
};

async function getAllSymbols(params) {
    const data = await makeApiRequest('https://app.stoxviews.com/api/searchsymbol',params);


    // console.log(data)
    let allSymbols = [];
    let k = {}

    for(const sym of data ){
        k = {}

        if(sym.fields['trading_symbol'] === null ){
            continue
        }
        else{
            k = {
                symbol: sym.fields["trading_symbol"],
                ticker: sym.fields["exchange_token"],
                description: sym.fields["name"],
                exchange: sym.fields["exchange"],
                type: sym.fields['instrument_type']
            }

            if(sym.fields['exchange'] !== 'NSE'){
                k.type = sym.fields['expiry'] + " " +sym.fields['instrument_type']
            }

        }

        allSymbols = [...allSymbols, k]
    }


    // for (const exchange of configurationData.exchanges) {
    //     const pairs = data.fields[exchange.value].pairs;

    //     for (const leftPairPart of Object.keys(pairs)) {
    //         const symbols = pairs[leftPairPart].map(rightPairPart => {
    //             const symbol = generateSymbol(exchange.value, leftPairPart, rightPairPart);
    //             return {
    //                 symbol: symbol.short,
    //                 ticker: symbol.full,
    //                 description: symbol.short,
    //                 exchange: exchange.value,
    //                 type: 'crypto',
    //             };
    //         });
    //         allSymbols = [...allSymbols, ...symbols];
    //     }
    // }
    return allSymbols;
}


function getFormattedDate(date) {
    // Get the current date and time
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');

    // Combine the parts into the desired format
    const formattedDate = `${year}-${month}-${day}`;

    return formattedDate;

}


function getFromDate() {
    // Get the current date and time
    const now = new Date();

    // Subtract 2000 days (2000 days * 24 hours * 60 minutes * 60 seconds * 1000 milliseconds)
    const pastDate = new Date(now.getTime() - 720 * 24 * 60 * 60 * 1000);

    // Get the formatted date
    return getFormattedDate(pastDate);
}


function formatDateToTimestamp(dateString, offsetHours, offsetMinutes) {
    // Create a Date object
    const date = new Date(dateString);

    // Time zone offset in minutes
    const timeZoneOffset = offsetHours * 60 + offsetMinutes;

    // Get the current time zone offset in minutes
    const currentTimeZoneOffset = date.getTimezoneOffset();

    // Calculate the difference
    const offsetDifference = timeZoneOffset - currentTimeZoneOffset;

    // Adjust the date by the offset difference
    date.setMinutes(date.getMinutes() + offsetDifference);

    // Format the adjusted date as an ISO string with the correct time zone offset
    const isoString = date.toISOString().split('.')[0];

    // Format the offset as +hh:mm or -hh:mm
    const formattedOffset = (offsetHours >= 0 ? '+' : '-') +
                            String(Math.abs(offsetHours)).padStart(2, '0') + ':' +
                            String(Math.abs(offsetMinutes)).padStart(2, '0');

    return isoString + formattedOffset;
}

// Get the date 2000 days ago in the desired format


// Get today's date in the desired format


function getAliceBlueDate(){
    const today = new Date();
    const twoYearsAgo = new Date(today);
    twoYearsAgo.setFullYear(today.getFullYear() - 3);
    
    // Convert the timestamps to milliseconds
    const fromTimestamp = twoYearsAgo.getTime();
    const toTimestamp = today.getTime();
    return [fromTimestamp,toTimestamp]
}

function getAngelOneData(resolution,from,to,symbolInfo){


    let fm_formated = new Date(from * 1000)
    let to_formated = new Date(to * 1000)

    fm_formated = getFormattedDate(fm_formated)
    to_formated = getFormattedDate(to_formated)

    if(resolution===1){
        let params = {
            "exchange":symbolInfo.exchange,
            "token":symbolInfo.ticker,
            "interval":"ONE_MINUTE",
            "from":fm_formated,
            "to":to_formated
        }

        return params
    }

    else {
        if(resolution === 60){

            let params = {
                "exchange":symbolInfo.exchange,
                "token":symbolInfo.ticker,
                "interval":"ONE_HOUR",
                "from":fm_formated,
                "to":to_formated
            }
    
            return params

        }

        else{
            let params = {
                "exchange":symbolInfo.exchange,
                "token":symbolInfo.ticker,
                "interval":"ONE_DAY",
                "from":fm_formated,
                "to":to_formated
            }
    
            return params

        }
    }

}


export default {
    onReady: (callback) => {
        // console.log('[onReady]: Method call');
        setTimeout(() => callback(configurationData));
    },
    searchSymbols: async (
        userInput,
        exchange,
        symbolType,
        onResultReadyCallback
    ) => {
        // console.log('[searchSymbols]: Method call');
        let symbols = []
        if(exchange=== ''){
            let params = {
                "exchange":null,
                "symbol": userInput
            }
            const gsymbols = await getAllSymbols(params);

            symbols = [...symbols,...gsymbols]

        }

        else{
            let params = {
                "exchange":exchange,
                "symbol": userInput

            }
            const gsymbols = await getAllSymbols(params);
            symbols = [...symbols,...gsymbols]
        }

        
        // console.log(exchange)
        const newSymbols = symbols.filter(symbol => {
            const isExchangeValid = exchange === '' || symbol.exchange === exchange;
            const isFullSymbolContainsInput = symbol.symbol
                .toLowerCase()
                .indexOf(userInput.toLowerCase()) !== -1;
            return isExchangeValid && isFullSymbolContainsInput;
        });
        // console.log(newSymbols)
        onResultReadyCallback(newSymbols);
    },
    resolveSymbol: async (symbolName, onSymbolResolvedCallback, onResolveErrorCallback, extension) => {
        // console.log('[resolveSymbol]: Method call', symbolName);

        let params = {
            "exchange":null,
            "symbol":symbolName
        }
        const symbols = await getAllSymbols(params);
        
        const symbolItem = symbols.find(( symbol ) => symbol.ticker === symbolName);
        if (!symbolItem) {
            // console.log('[resolveSymbol]: Cannot resolve symbol', symbolName);
            onResolveErrorCallback('Cannot resolve symbol');
            return;
        }

        // console.log("this is",symbolItem)
        // Symbol information object
        const symbolInfo = {
            ticker: symbolItem.ticker,
            name: symbolItem.symbol,
            description: symbolItem.description,
            type: symbolItem.type,
            session: '24x7',
            timezone: 'Asia/Kolkata',
            exchange: symbolItem.exchange,
            minmov: 1,
            pricescale: 100,
            has_intraday: true,
            visible_plots_set: 'ohlc',
            supported_resolutions: configurationData.supported_resolutions,
            volume_precision: 2,
            data_status: 'streaming',
        };
        // console.log('[resolveSymbol]: Symbol resolved', symbolName);
        onSymbolResolvedCallback(symbolInfo);
    
    },
    getBars: async (symbolInfo, resolution, periodParams, onHistoryCallback, onErrorCallback) => {
        const { from, to, firstDataRequest } = periodParams;
        // console.log('[getBars]: Method call', symbolInfo, resolution, from, to);

        try {

            // let f = new Date(from*1000)
            // let t = new Date(to*1000)

            // console.log("daterange", t.getDate() - f.getDate())

            let params = getAngelOneData(resolution,from,to,symbolInfo)
            // console.log("params",params)

            // let exch_seg = symbolInfo.exchange
            // let token = symbolInfo.ticker
            // // let tn = new Date()
            // // let to_day = getFormattedDate(tn)
            // // let frm_day = getFromDate()
            // let gdt = getAliceBlueDate()
            // let frm_day = gdt[0]
            // let to_day = gdt[1]
            // // frm_day,to_day = getAliceBlueDate()

            // let params ={
            //     "exchange": exch_seg,
            //     "token": token,
            //     "resolution": "1",
            //     "from": frm_day,
            //     "to": to_day
            // }

            // console.log("oarams",params)

            const data = await getCandleData("https://app.stoxviews.com/api/gethistoricaldata",params)
            // const data = await makeApiRequest(`data/histoday?${query}`);

            // console.log("historic data from stoxview",data)
            if ( data.length === 0) {
                // "noData" should be set if there is no data in the requested period
                onHistoryCallback([], { noData: true });
                return;
            }
            let bars = [];




            data.forEach(bar => {
                const timeMillis = new Date(bar[0]).getTime();
                // const timezoneOffsetMillis = new Date().getTimezoneOffset() * 60 * 1000;
                // const adjustedTimeMillis = timeMillis - timezoneOffsetMillis; 

                // console.log(" the date is ",timeMillis,timeMillis >= from *1000  && timeMillis <= to *1000)
                
    
                if (timeMillis >= from * 1000  && timeMillis <= to * 1000 ) {
                    // console.log("I am time")
                    bars = [...bars, {
                        time: timeMillis,
                        low: parseFloat(bar[3]),
                        high: parseFloat(bar[2]),
                        open: parseFloat(bar[1]),
                        close: parseFloat(bar[4]),
                        volume: parseFloat(bar[5]),
                    }];
                }
            });
            if (firstDataRequest) {
                lastBarsCache.set(`${symbolInfo.ticker}#${symbolInfo.exchange}`, { ...bars[bars.length - 1] });
            }
            // console.log(`[getBars]: returned ${bars.length} bar(s)`);
            // console.log(`[getBars]: returned ${bars.length} bar(s)`);
            onHistoryCallback(bars, { noData: false });
        } catch (error) {
            // console.log('[getBars]: Get error', error);
            onErrorCallback(error);
        }
    },
    subscribeBars: (symbolInfo, resolution, onRealtimeCallback, subscriberUID, onResetCacheNeededCallback) => {
        // console.log('[subscribeBars]: Method call with subscriberUID:', subscriberUID, symbolInfo);
        subscribeOnStream(
            symbolInfo,
            resolution,
            onRealtimeCallback,
            subscriberUID,
            onResetCacheNeededCallback,
            lastBarsCache.get(`${symbolInfo.ticker}#${symbolInfo.exchange}`)
        );
    },
    unsubscribeBars: (subscriberUID) => {
        // console.log('[unsubscribeBars]: Method call with subscriberUID:', subscriberUID);
        unsubscribeFromStream(subscriberUID);
    },
};