import { channelToSubscription } from "../../context/MyState";
//let tkn = JSON.parse(localStorage.getItem("sessionID"));
// console.log(tkn)

// if (tkn === null) {
// } else {
//   let url = `wss://ndyz9236e3.execute-api.us-east-1.amazonaws.com/test?token=${tkn}`;

//   const ws = new WebSocket(url);

//   const exchcode = ["NSE", "NFO", "MCX"];

//   ws.onopen = function () {
//     console.log("streaming websocket server connected.");
//     let k = {
//       action: "sendMessage",
//       message: {
//         event: "subscribe",
//         data: "t",
//       },
//     };
//     ws.send(JSON.stringify(k));
//   };

//   ws.onmessage = function (event) {
//     // console.log('Message from server: ', event.data);
//     // console.log(event.data)

//     let wdata = JSON.parse(event.data);
//     // console.log(wdata)

//     if (wdata.event === "tokenBroadcast") {
//       // console.log("ticker")
//       let dt = wdata.data;

//       dt.forEach((tick) => {
//         // console.log(tick)

//         // console.log(tick.exchange_token=='20293')
//         let dstring = tick.exchange_token;
//         const tradePrice = tick.last_price;
//         let tradeTime = new Date();
//         tradeTime = tradeTime.getTime();
//         const subscriptionItem = channelToSubscription.get(dstring);

//         if (subscriptionItem === undefined) {
//           // console.log("not find")
//           return;
//         }
//         // console.log("trade time",tradeTime)
//         const lastDailyBar = subscriptionItem.lastDailyBar;
//         const nextDailyBarTime = getNextDailyBarTime(lastDailyBar.time);

//         // console.log("found",subscriptionItem)
//         // const lastDailyBar = subscriptionItem.lastDailyBar;

//         // console.log("trade time: ",tradeTime,"and nextdailbartime",nextDailyBarTime)

//         // console.log("bool compare tradeTime vs nextDailyBarTime", tradeTime>=nextDailyBarTime)
//         let bar;
//         if (tradeTime >= nextDailyBarTime) {
//           // console.log("next daily")
//           bar = {
//             time: nextDailyBarTime,
//             open: tick.open,
//             high: tick.high,
//             low: tick.low,
//             close: tick.close,
//           };
//           // console.log('[socket] Generate new bar', bar);
//         } else {
//           // console.log("last daily")
//           bar = {
//             ...lastDailyBar,
//             high: Math.max(lastDailyBar.high, tick.last_price),
//             low: Math.min(lastDailyBar.min, tick.last_price),
//             close: tick.last_price,
//           };
//           // console.log('[socket] Update the latest bar by price', tradePrice);
//         }

//         // let bar = {
//         //     ...lastDailyBar,
//         //     high: wdata.data.high_price_of_the_day/100,
//         //     low: wdata.data.low_price_of_the_day/100,
//         //     close: wdata.data.closed_price/100,
//         // };

//         // console.log('[socket] Update the latest bar by price', tradePrice);
//         subscriptionItem.lastDailyBar = bar;

//         // Send data to every subscriber of that symbol
//         subscriptionItem.handlers.forEach((handler) => handler.callback(bar));
//       });
//     }
//   };

//   ws.onclose = function () {
//     // console.log('Disconnected from the WebSocket server');
//   };
// }

//const channelToSubscription = new Map();

export function getNextDailyBarTime(barTime) {
  const date = new Date(barTime * 1000);
  date.setDate(date.getDate() + 1);
  return date.getTime() / 1000;
}

export function subscribeOnStream(
  symbolInfo,
  resolution,
  onRealtimeCallback,
  subscriberUID,
  onResetCacheNeededCallback,
  lastDailyBar
) {
  // console.log("subscribe on streamcalled",symbolInfo)

  let channelString = symbolInfo.ticker;

  const handler = {
    id: subscriberUID,
    callback: onRealtimeCallback,
  };
  let subscriptionItem = channelToSubscription.get(channelString);
  if (subscriptionItem) {
    // Already subscribed to the channel, use the existing subscription
    subscriptionItem.handlers.push(handler);
    return;
  }
  subscriptionItem = {
    subscriberUID,
    resolution,
    lastDailyBar,
    handlers: [handler],
  };
  channelToSubscription.set(channelString, subscriptionItem);
  // console.log(channelToSubscription)
  // console.log('[subscribeBars]: Subscribe to streaming. Channel:', channelString);
  // socket.emit('SubAdd', { subs: [channelString] });
}

export function unsubscribeFromStream() {
  // To Do
}
