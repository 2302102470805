import React, { useContext } from "react";
import Footer from "../footer/Footer";
import Navbar from "../navbar/Navbar";
import "./layout.css";
import MyContext from "../../context/MyContext";
import WatchList from "../watchlist/WatchList";

function Layout({ children }) {
  const {
    watchListShown,
    watch_list,
    set_watch_list,
    isChildAccount,
    isDemoPage,
  } = useContext(MyContext);

  return (
    // <div className="flex flex-col">

    //   <Navbar />
    //   <div className="flex flex-row">
    //     <div style={{ marginLeft: "40px" }}>
    //       <WatchList />
    //     </div>
    //     <div className="flex flex-1">{children}</div>
    //   </div>
    //   {/* <Footer /> */}
    // </div>
    isChildAccount ? (
      <div>
        {/* <TopBar /> */}
        <div className="nav-bar">
          <Navbar />
        </div>
        <div>
          <div style={{ width: "100%", }}>{children}</div>
        </div>
      </div>
    ) : (
      <div className="app">
        {/* <TopBar /> */}
        <div className="nav-bar">
          <Navbar />
        </div>
        <div className="main-content">
          {watchListShown ? <WatchList /> : <></>}
          {/* <WatchList/> */}
          <div className="home" style={{ width: "100%",marginTop:15 }}>
            {children}
          </div>
        </div>
      </div>
    )
  );
}

export default Layout;
