import React, { useEffect, useContext } from "react";
import Layout from "../../components/layout/Layout";
import MyContext from "../../context/MyContext";
import TVCharts from "../../components/TradingViewChart/TVcharts";
import { useLocation } from "react-router-dom";
function Charts() {
  const { setWatchListShown, setPageName } = useContext(MyContext);
  const location = useLocation();
  // console.log("location : ", location);
  const { token } = location.state || {};

  useEffect(() => {
    // setWatchListShown(true)
    setPageName("charts");
  });
  return (
    <Layout>
      <div style={{ marginLeft: 10, paddingBottom: 10 }}>
        <TVCharts />
      </div>
    </Layout>
  );
}

export default Charts;
