import React from "react";

const NotFound = () => {
  return (
    <div className="flex w-full h-full items-center justify-center flex-col">
      <h1>404 - Page Not Found</h1>
      <p>Sorry, the page you are looking for does not exist.</p>
    </div>
  );
};

export default NotFound;
