import React, { useContext, useState, Fragment, useEffect } from "react";
import Modal from "react-modal";
import Loader from "../../components/loader/Loader";
import upIcon from "../../assets/icons/up.png";
import rupeeIcon from "../../assets/icons/rupee.png";
import downgray from "../../assets/icons/downgray.png";
import { toast } from "react-toastify";
import { Tab } from "@headlessui/react";
import MyContext from "../../context/MyContext";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    // marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "white",
    width: "522px",
    // height: "343px",
    padding: "0px",
    borderRadius: "4px",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.3)",
  },
};

function BuyAndSellModal() {
  const {
    setPageName,
    loading,
    setLoading,
    userprofile,
    isToggled,
    setIsToggled,
    bopen,
    setBopen,
    buyAndSellAction,
    setBuyAndSellAction,
    buyAndSellExchange,
    buyAndSellTradingSymbol,
    buyAndSellInstrumentType,
    buyAndSellExchangeToken,
    chartToken,
    qty,
    setQty,
    buyAndSellModalLivePrice,
    setBuyAndSellModalLivePrice,
    lotSize,
  } = useContext(MyContext);

  const [trading_symbol, setTrading_Symbol] = useState("");
  const [instrument_type, setInstrument_type] = useState("");
  const [validity, setValidity] = useState("DAY");
  const [selectedOption, setSelectedOption] = useState("");
  const [productType, setProductType] = useState("Intraday");
  const [orderType, setOrderType] = useState("Limit");
  const [isDisabled, setIsDisabled] = useState(false);
  const [exchange, setExchange] = useState("");
  const [isMoreDropdown, setIsMoreDropdown] = useState(false);
  const [activeTab, setActiveTab] = useState("regular");
  //   const [isToggled, setIsToggled] = useState(false);
  const [isBuy, setIsBuy] = useState("Buy");
  const [token, setToken] = useState("");

  const tabsData = [
    { label: "Regular", value: "regular", desc: "Content for Regular" },
    { label: "Cover", value: "cover", desc: "Content for Cover" },
    { label: "AMO", value: "amo", desc: "Content for AMO" },
  ];

  const handleToggle = () => {
    if (!isToggled) {
      setIsBuy("Sell");
      setBuyAndSellAction("sell");
    } else {
      setIsBuy("Buy");
      setBuyAndSellAction("buy");
    }
    setIsToggled(!isToggled);
  };

  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
  };

  const onClickBuyAndSell = async () => {
    setLoading(true);
    const sessionID = JSON.parse(localStorage.getItem("sessionID"));
    const body = {
      token: chartToken,
      trading_symbol: buyAndSellTradingSymbol,
      exchange: buyAndSellExchange,
      action: buyAndSellAction,
      order_type: orderType,
      product_type: productType,
      qty: qty,
      validity: validity,
    };

    console.log("buy and sell : ", body);

    await fetch("https://app.stoxviews.com/api/placeorder", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionID}`,
      },
      body: JSON.stringify(body),
    })
      .then((res) => res.json())
      .then((value) => {
        // console.log("buy and sell : ",value);
        if (value.status) {
          setLoading(false);
          toast.success(`${buyAndSellAction} successfully`, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setBopen(false);
          setQty("");
        } else {
          setLoading(false);
          toast.error(`${buyAndSellAction} Failed`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setBopen(false);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        toast.error(`${buyAndSellAction} Failed`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
  };

  return (
    <div>
      <Modal
        isOpen={bopen}
        ariaHideApp={false}
        // onAfterOpen={afterOpenModal}
        onRequestClose={() => setBopen(false)}
        style={customStyles}
      >
        <div>
          {/* {loading && <Loader />} */}
          <div
            className="relative pt-[16px] pb-[19px] pl-[19px]"
            style={{
              backgroundColor: !isToggled ? "#D4EDDA" : "rgb(248, 215, 218)",
            }}
          >
            <div className="flex flex-row items-center justify-between">
              <div className="flex flex-col">
                <div
                  className="mb-[8px]"
                  style={{
                    fontSize: 14,
                    color: "#155724",
                    fontWeight: "600",
                  }}
                >
                  {buyAndSellTradingSymbol}
                  {/* ({buyAndSellInstrumentType}) */}
                </div>
                <div className="flex flex-row">
                  <div className="mr-[10px]">
                    <label className="mr-[5px]">
                      <input
                        type="radio"
                        value="BSE"
                        checked={selectedOption === "BSE"}
                        onChange={handleOptionChange}
                      />
                    </label>
                    <span>BSE: {buyAndSellModalLivePrice}</span>
                  </div>
                  <div>
                    <label className="mr-[5px]">
                      <input
                        type="radio"
                        value="NSE"
                        checked={selectedOption === "NSE"}
                        onChange={handleOptionChange}
                      />
                    </label>
                    <span>NSE: {buyAndSellModalLivePrice}</span>
                  </div>
                </div>
              </div>
              <div className="flex  items-center justify-center ml-[8px] mr-[8px]  bg-gray-100">
                <div
                  className="mr-[10px]"
                  style={{
                    fontSize: 12,
                    color: "#817E7E",
                    fontWeight: "700",
                  }}
                >
                  Buy
                </div>
                <button
                  className={`w-14 h-6 flex items-center rounded-full p-1 duration-300 ease-in-out`}
                  style={{
                    backgroundColor: !isToggled ? "#39A245" : "#721c24",
                  }}
                  onClick={() => handleToggle()}
                >
                  <div
                    style={{ backgroundColor: "white" }}
                    className={` w-4 h-4  rounded-full shadow-md transform duration-300 ease-in-out ${
                      isToggled ? "translate-x-7" : "translate-x-1"
                    }`}
                  />
                </button>
                <div
                  className="ml-[10px]"
                  style={{
                    fontSize: 12,
                    color: "#817E7E",
                    fontWeight: "700",
                  }}
                >
                  Sell
                </div>
              </div>
            </div>
          </div>

          <div className="w-full max-w-md px-2 sm:px-0">
            <Tab.Group
              selectedIndex={tabsData.findIndex(
                (tab) => tab.value === activeTab
              )}
              onChange={(index) => setActiveTab(tabsData[index].value)}
            >
              <Tab.List className="flex  space-x-1 bg-gray-200 rounded-xl">
                {tabsData.map(({ label, value }) => (
                  <Tab key={value} as={Fragment}>
                    {({ selected }) => (
                      <button
                        className={`w-full py-2.5 text-sm leading-5 font-medium text-gray-700  
                ${
                  selected
                    ? "bg-white shadow text-gray-900 border-b-2 border-t-0 border-l-0 border-r-0 border-gray-900 text-activeTabColor"
                    : "text-gray-700 hover:bg-white/[0.12] hover:text-gray-900 text-inactiveTabColor"
                }`}
                      >
                        {label}
                      </button>
                    )}
                  </Tab>
                ))}
              </Tab.List>
            </Tab.Group>
          </div>

          <div className="flex flex-row items-center justify-between mb-[17px] mt-[24px] ml-[20px] mr-[20px]">
            <div className="flex flex-row items-center mt-[8px]">
              <button
                className=" mr-[8px] w-[96px] h-[24px]"
                style={{
                  backgroundColor:
                    productType === "Intraday" ? "#D4EDDA" : "#E6E6E6",
                  borderRadius: 4,
                  fontSize: 12,
                  color: !isToggled ? "#363636" : "#721c24",
                }}
                onClick={() => {
                  setProductType("Intraday");
                }}
              >
                Intraday
                <span style={{ fontSize: 8, color: "#817E7E", marginLeft: 5 }}>
                  MIS
                </span>
              </button>
              <button
                className=" mr-[8px] w-[96px] h-[24px]"
                style={{
                  backgroundColor:
                    productType === "Longterm" ? "#D4EDDA" : "#E6E6E6",
                  borderRadius: 4,
                  fontSize: 12,
                  color: "#363636",
                }}
                onClick={() => {
                  setProductType("Longterm");
                }}
              >
                Longterm
                <span style={{ fontSize: 8, color: "#817E7E", marginLeft: 5 }}>
                  CNC
                </span>
              </button>
            </div>

            <div className="flex flex-row justify-between mt-[8px]">
              <div className="flex flex-row items-center ">
                <button
                  className="mr-[8px] w-[60px] h-[24px]"
                  style={{
                    backgroundColor:
                      orderType === "Limit" ? "#D4EDDA" : "#E6E6E6",
                    borderRadius: 4,
                    fontSize: 12,
                    color: !isToggled ? "#155724" : "#721c24",
                  }}
                  onClick={() => {
                    setIsDisabled(false);
                    setOrderType("Limit");
                  }}
                >
                  Limit
                </button>
                <button
                  className="w-[64px] h-[24px]"
                  style={{
                    backgroundColor:
                      orderType === "Market" ? "#D4EDDA" : "#E6E6E6",
                    borderRadius: 4,
                    fontSize: 12,
                    color: "#363636",
                  }}
                  onClick={() => {
                    setIsDisabled(true);
                    setOrderType("Market");
                  }}
                >
                  Market
                </button>
              </div>
              <div className="flex flex-row items-center ml-[8px]">
                <button
                  className="mr-[8px] w-[48px] h-[24px]"
                  style={{
                    backgroundColor: orderType === "SL" ? "#D4EDDA" : "#E6E6E6",
                    borderRadius: 4,
                    fontSize: 12,
                    color: "#363636",
                  }}
                  onClick={() => {
                    setIsDisabled(true);
                    setOrderType("SL");
                  }}
                >
                  SL
                </button>
                <button
                  className="w-[48px] h-[24px]"
                  style={{
                    backgroundColor:
                      orderType === "SL-M" ? "#D4EDDA" : "#E6E6E6",
                    borderRadius: 4,
                    fontSize: 12,
                    color: "#363636",
                  }}
                  onClick={() => {
                    setIsDisabled(true);
                    setOrderType("SL-M");
                  }}
                >
                  SL-M
                </button>
              </div>
            </div>
          </div>

          <div className="flex flex-row items-center justify-between ml-[20px] mr-[20px]">
            <div>
              <div
                style={{ fontSize: 12, color: "#817E7E" }}
                className="mb-[8px]"
              >
                Quantity
              </div>
              <input
                type="text"
                value={qty}
                onChange={(e) => {
                  setQty(e.target.value);
                }}
                style={{
                  width: 144,
                  height: 32,
                  borderRadius: 4,
                  borderStyle: "solid",
                  borderWidth: 1,
                  borderColor: "rgba(217, 217, 217, 1.0)",
                  paddingLeft: 8,
                  paddingRight: 8,
                }}
              />
            </div>

            <div>
              <div
                style={{ fontSize: 12, color: "#817E7E" }}
                className="mb-[8px]"
              >
                Price
              </div>
              <input
                type="text"
                disabled={isDisabled}
                style={{
                  width: 144,
                  height: 32,
                  borderRadius: 4,
                  borderStyle: "solid",
                  borderWidth: 1,
                  borderColor: "rgba(217, 217, 217, 1.0)",
                  paddingLeft: 8,
                  paddingRight: 8,
                }}
              />
            </div>

            <div>
              <div
                style={{ fontSize: 12, color: "#817E7E" }}
                className="mb-[8px]"
              >
                Trigger
              </div>
              <input
                type="text"
                style={{
                  width: 144,
                  height: 32,
                  borderRadius: 4,
                  borderStyle: "solid",
                  borderWidth: 1,
                  borderColor: "rgba(217, 217, 217, 1.0)",
                }}
              />
            </div>
          </div>

          <div className="flex flex-row items-center mt-[6px] ml-[20px] mr-[20px]">
            <span className="mr-[8px]" style={{ fontSize: 14,fontWeight: '500', }}>
              Lot Size :
            </span>
            <span className="mr-[8px]" style={{ fontSize: 14,fontWeight: '500', }}>
              {lotSize}
            </span>
          </div>

          <div className="flex flex-row items-center mt-[16px] ml-[20px] mr-[20px]">
            <span className="mr-[8px]" style={{ fontSize: 12 }}>
              More
            </span>
            <img
              onClick={() => setIsMoreDropdown(!isMoreDropdown)}
              src={isMoreDropdown ? upIcon : downgray}
              alt="up icon"
              className={`w-[8px] h-[5.16px] cursor-pointer `}
            />
          </div>

          {!isMoreDropdown && (
            <div className="flex flex-row justify-end ml-[20px] mr-[20px]">
              <div className="flex flex-row items-center">
                <div className="flex flex-col mr-[100px] ">
                  <span style={{ fontSize: 12, color: "#817E7E" }}>
                    Validity
                  </span>
                  <div className="flex flex-row items-center mt-[8px]">
                    <button
                      className="mr-[8px] w-[50px] h-[24px]"
                      style={{
                        backgroundColor:
                          validity === "DAY" ? "#D4EDDA" : "#E6E6E6",
                        borderRadius: 4,
                        fontSize: 12,
                        color: !isToggled ? "#155724" : "#721c24",
                      }}
                      onClick={() => {
                        setValidity("DAY");
                      }}
                    >
                      DAY
                    </button>
                    <button
                      className="w-[50px] h-[24px]"
                      style={{
                        backgroundColor:
                          validity === "IOC" ? "#D4EDDA" : "#E6E6E6",
                        borderRadius: 4,
                        fontSize: 12,
                        color: "#155724",
                      }}
                      onClick={() => {
                        setValidity("IOC");
                      }}
                    >
                      IOC
                    </button>
                  </div>
                </div>
                <div className="flex flex-col">
                  <span style={{ fontSize: 12, color: "#817E7E" }}>
                    Disclosed Qty.
                  </span>
                  <input
                    className="mt-[8px]"
                    type="text"
                    style={{
                      width: 144,
                      height: 32,
                      borderRadius: 4,
                      borderStyle: "solid",
                      borderWidth: 1,
                      borderColor: "rgba(217, 217, 217, 1.0)",
                      paddingLeft: 8,
                      paddingRight: 8,
                    }}
                  />
                </div>
              </div>
            </div>
          )}

          <div
            style={{ borderWidth: 0.5, borderColor: "#E6E6E6" }}
            className="mt-[16px] mb-[16px]"
          />

          <div className="flex flex-row justify-between items-center mb-[20px] ml-[20px] mr-[20px]">
            <div className="flex flex-row items-center">
              <span className="mr-[8px]">Margin</span>
              <img
                src={rupeeIcon}
                alt="Ruppe icon"
                className="w-[12px] h-[12px] mr-[3px]"
              />
              <span
                className="mr-[8px]"
                style={{ fontSize: 16, color: "#817E7E" }}
              >
                {/* {Object.keys(userprofile).length !== 0
                  ? userprofile?.balance?.toFixed(2)
                  : "0.00"} */}
                {qty === ""
                  ? buyAndSellModalLivePrice*
                  parseInt(lotSize)
                  : parseInt(buyAndSellModalLivePrice) *
                    parseInt(qty) *
                    parseInt(lotSize)}
              </span>
            </div>
            <div className="flex flex-row items-center ">
              <button
                onClick={() => onClickBuyAndSell()}
                className="mr-[8px] w-[96px] h-[32px]"
                style={{
                  backgroundColor: !isToggled
                    ? "#D4EDDA"
                    : "rgb(248, 215, 218)",
                  borderRadius: 4,

                  color: !isToggled ? "rgb(114, 28, 36)" : "rgb(21, 87, 36)",
                }}
              >
                {!isToggled ? "Buy" : "Sell"}
              </button>
              <button
                className="w-[96px] h-[32px]"
                style={{ backgroundColor: "#E6E6E6", borderRadius: 4 }}
                onClick={() => {
                  setBopen(false);
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default BuyAndSellModal;
