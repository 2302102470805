import React, { useEffect, useContext, useState } from "react";
import Layout from "../../components/layout/Layout";
import MyContext from "../../context/MyContext";
import DownloadIcon from "../../assets/icons/download.png";
import { FiSearch } from "react-icons/fi";
import { MdDownload } from "react-icons/md";
import rupeeIcon from "../../assets/icons/rupee.png";

function Holdings() {
  const { setWatchListShown, setPageName } = useContext(MyContext);
  const [isShown, setIsShown] = useState(false);
  useEffect(() => {
    // setWatchListShown(true);
    setPageName("Holdings");
  });
  return (
    <Layout>
      <div
        className="w-full h-auto border-[1px] mb-[10px]"
        style={{ borderColor: "#E6E6E6", borderRadius: 4 }}
      >
        <div className="flex  items-center justify-between ml-[16px] mt-[24px] mr-[16px] mb-[23px]">
          <div className="flex  items-center ">
            <h1 className="text-xl font-bold mr-[36px]">Holdings</h1>
            <div className="flex space-x-1">
              <button
                className="px-3 py-1 border  bg-green-100 flex flex-row items-center"
                style={{
                  fontSize: 14,
                  backgroundColor: "#D4EDDA",
                  color: "#155724",
                  //color: isAllSelected ? "#155724" : "#817E7E",
                  //backgroundColor: isAllSelected ? "#D4EDDA" : "#FFFFFF",
                }}
              >
                Stocks | 05
              </button>
            </div>
          </div>

          <div>
            <button>
              <FiSearch />
            </button>
            <button style={{ width: 14, height: 16, marginLeft: 16 }}>
              <MdDownload />
            </button>
          </div>
        </div>

        <table className="min-w-full  bg-white orderTable">
          <thead className="orderTable">
            <tr>
              <th>Instrument</th>
              <th>Qty.</th>
              <th>Avg. cost</th>
              <th>LTP</th>
              <th>Cur. val</th>
              <th>P&L</th>
              <th>Day Profit</th>
              <th>Net chg.</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="px-4 py-2 border orderTabletd">
                <div
                  className=""
                  onMouseOver={() => setIsShown(true)}
                  onMouseLeave={() => setIsShown(false)}
                >
                  <div className="flex items-center">
                    <h2 style={{ fontSize: 14, color: "#363636" }}>JP POWER</h2>
                    <span
                      className="ml-[4px]"
                      style={{ fontSize: 10, color: "#817E7E" }}
                    >
                      NSE
                    </span>
                  </div>
                  {isShown && (
                    <div className="addorexit">
                      <button className="addBtn">
                        <p>Add</p>
                      </button>
                      <button className="exitBtn">
                        <p>Exit</p>
                      </button>
                    </div>
                  )}
                </div>
              </td>
              <td className="px-4 py-2 border orderTabletd">11</td>
              <td className="px-4 py-2 border orderTabletd">7.43</td>
              <td className="px-4 py-2 border orderTabletd">6.52</td>
              <td className="px-4 py-2 border orderTabletd">9.08</td>
              <td className="px-4 py-2 border orderTabletd">-10.03</td>
              <td className="px-4 py-2 border orderTabletd">1000.27</td>
              <td className="px-4 py-2 border orderTabletd">50.00%</td>
            </tr>
          </tbody>
        </table>

        <div className="mt-[27px] mb-[15px]  flex justify-end space-x-9 px-6 ">
          <div>
            <div className="text-xs text-gray-500">Total Investment</div>
            <div className="flex flex-row items-center">
              <img
                src={rupeeIcon}
                alt="Rupee icon"
                className="w-3.5 h-3.5 mr-1.5"
              />
              <span className="text-lg font-bold">0.00</span>
            </div>
          </div>

          <div>
            <div className="text-xs text-gray-500">Current Value</div>
            <div className="flex flex-row items-center">
              <img
                src={rupeeIcon}
                alt="Rupee icon"
                className="w-3.5 h-3.5 mr-1.5"
              />
              <span className="text-lg font-bold">0.00</span>
            </div>
          </div>

          <div>
            <div className="text-xs text-gray-500">Day P&L</div>
            <div className="flex flex-row items-center">
              <img
                src={rupeeIcon}
                alt="Rupee icon"
                className="w-3.5 h-3.5 mr-1.5"
              />
              <span className="text-lg font-bold">0.00(%)</span>
            </div>
          </div>

          <div>
            <div className="text-xs text-gray-500">Today's P&L</div>
            <div className="flex flex-row items-center">
              <img
                src={rupeeIcon}
                alt="Rupee icon"
                className="w-3.5 h-3.5 mr-1.5"
              />
              <span className="text-lg font-bold">0.00(%)</span>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default Holdings;
