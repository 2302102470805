import React, { useEffect, useContext } from "react";
import Layout from "../../components/layout/Layout";
import MyContext from "../../context/MyContext";
import "../../styles/brokerintegration.css";
import IntegrateChild from "../../components/broker/IntegrateChild";
import sampleVideo from "../../assets/icons/sampleVideo.mp4";

function BrokerIntegration() {
  const { setPageName } = useContext(MyContext);

  useEffect(() => {
    setPageName("brokerintegration");
  }, []);

  return (
    <Layout>
      <div className="pl-[12px]  container">
        <h2 className="mb-[21px] mt-[24px]">Broker integration</h2>
        <div className="broker-container">
          <div className="broker-left">
            <IntegrateChild isBrokerIntegrationPage={true} />
          </div>

          <div className="broker-right">
            <h2>How to Integrate Alice Blue Account</h2>
            <video
              src={sampleVideo}
              style={{ width: 389, height: 286 }}
              controls="controls"
            ></video>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default BrokerIntegration;
