import React, { useState } from "react";
// import styles from "./toggleStyle.module.css";
import './toggleStyle.css';

function ToggleSwitch() {
  const [isOnline, setIsOnline] = useState(false);

  const handleToggle = () => {
    setIsOnline(!isOnline);
  };

  return (
    <div>
      <label className="toggleswitch">
        <input
          type="checkbox"
          checked={isOnline}
          onChange={handleToggle}
        />
        <span className="slider"></span>
        <span className="statustext">{isOnline ? 'Live' : 'Offline'}</span>
      </label>
    </div>
  );
}

export default ToggleSwitch;
