import React, { useContext, useEffect } from "react";
import Layout from "../../components/layout/Layout";
import MyContext from "../../context/MyContext";
import "../../styles/optionchain.css";
import Navbar from "../../components/navbar/Navbar";
import TVCharts from "../../components/TradingViewChart/TVcharts";

function Optionchain() {
  const { setWatchListShown, pageName, setPageName, setIsChildAccount } =
    useContext(MyContext);

  useEffect(() => {
    setPageName("optionchain");
  }, []);

  return (
    <Layout>
      <div style={{ marginLeft: 10,paddingBottom:10 }}>
        <TVCharts />
      </div>
    </Layout>
  );
}

export default Optionchain;
