import React, { useState, useContext } from "react";
import "../signin/signinStyle.css";
import stlogin from "../../assets/icons/stlogin.png";
import stoxview from "../../assets/icons/stoxview.png";
import Google from "../../assets/icons/Google.png";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import MyContext from "../../context/MyContext";
import Loader from "../../components/loader/Loader"

function Signup() {
  const { loading, setLoading } = useContext(MyContext);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const navigate = useNavigate();

  const onGetStarted = async () => {
    setLoading(true);
    const body = {
      email: email,
      password: password,
      fname: firstName,
      lname: lastName,
    };

    console.log("body : ", body);

    await fetch("https://app.stoxviews.com/api/signup", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    })
      .then((res) => res.json())
      .then((value) => {
        console.log("value :", value);
        if (value.status === "Success") {
          setLoading(false);
          toast.success("Signup Successfully", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          localStorage.setItem("sessionID", JSON.stringify(value.sessionID));
          localStorage.setItem("email", JSON.stringify(email));
          navigate("/dashboard");
        } else {
          setLoading(false);
          toast.error(`${value.message}`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      })
      .catch((err) => {
        setLoading(false);
        toast.error("Signup Failed", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        console.log(err);
      });
    //window.location.href = "/";
  };

  return (
    <div className="flex flex-row items-center justify-center">
      {loading && <Loader />}
      <div className="mr-[147.76px] mt-[30px] mb-[30px]">
        <img
          src={stoxview}
          alt="logo icon"
          className="w-[129px] h-[30px] mb-[48px]"
        />
        <img
          src={stlogin}
          alt="login icon"
          className="w-[614.24px] h-[391.36px] mb-[48px]"
        />
        <span
          className="font-light"
          style={{ fontSize: 12, fontWeight: "400" }}
        >
          Copyright @ 2024 Stoxview. All Rights Reserved.
        </span>
      </div>

      <div
        className="w-[326px] cardStyle"
        style={{
          borderColor: "#E6E6E6",
          padding: 32,
          borderRadius: 6,
          borderWidth: 1,
        }}
      >
        <div
          className="flex items-center justify-center h-[40px]"
          style={{ borderWidth: 1, borderColor: "#E6E6E6", borderRadius: 6 }}
        >
          <button className="flex items-center">
            <img
              src={Google}
              alt="Google icon"
              className="mr-[16px] w-[16px] h-[16px]"
            />
            <span style={{ fontSize: 12, color: "#363636" }}>
              Signup With Google
            </span>
          </button>
        </div>

        <div className="flex mt-[16px] items-center">
          <div
            className="w-[117px] h-[0px] mr-[8px]"
            style={{ borderColor: "#D9D9D9", borderWidth: 1 }}
          ></div>
          <span>or</span>
          <div
            className="w-[117px] h-[0px] ml-[8px]"
            style={{ borderColor: "#D9D9D9", borderWidth: 1 }}
          ></div>
        </div>

        <h1 className="welcomeBack mb-[16px]  text-center">
          Create an account
        </h1>

        <section className="mb-[12px]">
          <h2 className="heading">First Name</h2>
          <div
            style={{
              marginBottom: "8px ",
              height: 32,
              borderWidth: 1,
              borderRadius: 4,
              borderStyle: "solid",
              borderColor: "rgba(217, 217, 217, 1.0)",
            }}
          >
            <input
              type="text"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              placeholder="Enter your first name"
              style={{ fontSize: 12, marginLeft: 16, width: "90%" }}
              className="bg-gray-100 outline-none  text-gray-500"
            />
          </div>
        </section>

        <section className="mb-[12px]">
          <h2 className="heading">First Name</h2>
          <div
            style={{
              marginBottom: "8px ",
              height: 32,
              borderWidth: 1,
              borderRadius: 4,
              borderStyle: "solid",
              borderColor: "rgba(217, 217, 217, 1.0)",
            }}
          >
            <input
              type="text"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              placeholder="Enter your last name"
              style={{ fontSize: 12, marginLeft: 16, width: "90%" }}
              className="bg-gray-100 outline-none  text-gray-500"
            />
          </div>
        </section>

        <section className="mb-[12px]">
          <h2 className="heading">Email ID</h2>
          <div
            style={{
              marginBottom: "8px ",
              height: 32,
              borderWidth: 1,
              borderRadius: 4,
              borderStyle: "solid",
              borderColor: "rgba(217, 217, 217, 1.0)",
            }}
          >
            <input
              type="email"
              placeholder="Enter your email id"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              style={{ fontSize: 12, marginLeft: 16, width: "90%" }}
              className="bg-gray-100 outline-none w-full text-gray-500"
            />
          </div>
        </section>

        <section className="mb-[24px]">
          <h2 className="heading">Password</h2>
          <div
            style={{
              marginBottom: "8px ",
              height: 32,
              borderWidth: 1,
              borderRadius: 4,
              borderStyle: "solid",
              borderColor: "rgba(217, 217, 217, 1.0)",
            }}
          >
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Enter your password"
              style={{ fontSize: 12, marginLeft: 16, width: "90%" }}
              className="bg-gray-100 outline-none w-full text-gray-500"
            />
          </div>
        </section>

        <div
          className="mb-[16px] h-[40px] w-[262px] flex items-center justify-center text-center"
          style={{ backgroundColor: "#39A245", borderRadius: 4 }}
        >
          <button
            style={{ fontSize: 12, color: "#FFFFFF" }}
            onClick={onGetStarted}
          >
            Get Started
          </button>
        </div>

        <div className=" text-center">
          <span style={{ fontSize: 12 }}>Already have an account?</span>
          <Link
            to="/"
            style={{ fontSize: 12, marginLeft: 4, textDecoration: "underline" }}
          >
            Log in
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Signup;
