import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  sessionID: JSON.parse(localStorage.getItem("sessionID")) || null,
};

const sessionSlice = createSlice({
  name: "session",
  initialState,
  reducers: {
    setSessionID: (state, action) => {
      state.sessionID = action.payload;
      localStorage.setItem("sessionID", JSON.stringify(action.payload));
    },
  },
});

export const { setSessionID } = sessionSlice.actions;

export default sessionSlice.reducer;
