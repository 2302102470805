import React, { useState, useEffect, useContext } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import ToggleSwitch from "../toggle/ToggleSwitch";
import stoxview from "../../assets/icons/stoxview.png";
import vector from "../../assets/icons/vector.png";
import userIcon from "../../assets/icons/user.png";
import searchIcon from "../../assets/icons/search.png";
import down from "../../assets/icons/down.png";
import redDown from "../../assets/icons/redDown.png";
import lock from "../../assets/icons/lock.png";
import key from "../../assets/icons/key.png";
import logout from "../../assets/icons/logout.png";
import copy from "../../assets/icons/copy.png";
import TopBar from "./TopBar";
import MyContext from "../../context/MyContext";
import Loader from "../loader/Loader";
import { toast } from "react-toastify";
import "../../App.css";

const Header = () => {
  const { headLivePrice } = useContext(MyContext);
  const percentage = (headLivePrice?.change / headLivePrice?.last_price) * 100;
  return headLivePrice.last_price !== 0.0 ? (
    <div className="flex items-center space-x-2">
      <div className="text-gray-500">Nifty 50</div>
      <div style={{ color: "rgb(0,0,0,.5)" }} className="border-r-2 ">
        &nbsp;
      </div>
      <div className="font-semibold">
        {headLivePrice?.last_price === 0.0
          ? " "
          : headLivePrice?.last_price?.toFixed(2)}
      </div>
      <div style={{ color: "rgb(0,0,0,.5)" }} className="border-r-2 ">
        &nbsp;
      </div>
      <div
        // className="text-customGreen"
        className="flex flex-row items-center"
        style={{
          color: headLivePrice?.change <= 0 ? "rgb(223, 33, 35)" : "green",
        }}
      >
        {`${
          headLivePrice?.change === 0.0
            ? " "
            : headLivePrice?.change?.toFixed(2)
        } `}
        <div
          style={{
            color: headLivePrice?.change <= 0 ? "rgb(223, 33, 35)" : "green",
          }}
        >{` ${
          isNaN(percentage) ? "" : "(" + percentage.toFixed(2) + "%)"
        }`}</div>
      </div>
      {headLivePrice?.change !== 0.0 && (
        <img
          src={headLivePrice?.change <= 0 ? redDown : vector}
          alt="up icon"
          style={{ width: "8px", height: "5.16px" }}
          className="h-6"
        />
      )}
    </div>
  ) : (
    <div></div>
  );
};

function Navbar() {
  const {
    isDemoPage,
    setIsDemoPage,
    isLivePage,
    setIsLivePage,
    loading,
    setLoading,
    userprofile,
    isChildAccount,
    setPageName,
    ws,
    headLivePrice,
    isScriptOrBased,
    setIsScriptOrBased,
    setIsMasterOrChildOrder,
    setIsMasterOrChildPosition,
  } = useContext(MyContext);

  const [isToggled, setIsToggled] = useState(false);
  const [isLiveToggled, setIsLiveToggled] = useState(true);
  const [profileOption, setProfileOption] = useState(false);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [orderdropdownVisible, setOrderDropdownVisible] = useState(false);
  const [positiondropdownVisible, setPositionDropdownVisible] = useState(false);
  const navigate = useNavigate();

  const handleToggle = () => {
    setIsToggled(!isToggled);
  };

  const handleLiveToggle = () => {
    if (!isDemoPage) {
      setPageName("dashboard");
      navigate("/dashboard");
      setIsDemoPage(true);
      localStorage.setItem("currentDashboard", "demo");
    } else {
      setIsDemoPage(false);
      setPageName("dashboard");
      navigate("/dashboard");

      localStorage.setItem("currentDashboard", "live");
    }
  };

  const openProfileOption = () => {
    setProfileOption(!profileOption);
  };

  const handleClickOutside = (event) => {
    setProfileOption(false);
  };

  const [copied, setCopied] = useState(false);
  const handleCopyClick = (textToCopy) => {
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        setCopied(true);
        // console.log("key copied successfully.");
        toast.success("Copy Successfully", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setTimeout(() => setCopied(false), 2000);
      })
      .catch((err) => {
        toast.error("Copy Failed", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        console.error("Failed to copy text: ", err);
      });
    setProfileOption(false);
  };

  const logoutCall = async () => {
    setLoading(true);
    const email = JSON.parse(localStorage.getItem("email"));
    const sessionID = JSON.parse(localStorage.getItem("sessionID"));

    const body = {
      email: email,
    };
    console.log("body : ", body);

    await fetch("https://app.stoxviews.com/api/logout", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionID}`,
      },
      body: JSON.stringify(body),
    })
      .then((res) => res.json())
      .then((value) => {
        // console.log("logout ", value);
        if (value.status === "Success") {
          setLoading(false);
          toast.success("Logout Successfully", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          localStorage.clear("");
          if (ws.current) {
            ws.current.close();
          }
          navigate("/");
          setProfileOption(false);
        } else {
          setLoading(false);
          toast.error("Logout Failed", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
        if (value.status === "AuthError") {
          // toast.error(`${value.message}`, {
          //   position: "top-right",
          //   autoClose: 5000,
          //   hideProgressBar: true,
          //   closeOnClick: true,
          //   pauseOnHover: true,
          //   draggable: true,
          //   progress: undefined,
          //   theme: "colored",
          // });
          localStorage.clear("");
          window.location.href = "/login";
        }
      })
      .catch((err) => {
        setLoading(false);

        toast.error("Logout Failed", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        localStorage.clear("");
        window.location.href = "/login";
        console.log(err);
      });
  };

  useEffect(() => {
    // console.log("userprofile nav :",userprofile);
    // navigate("/dashboard");
    if (isChildAccount) {
      if (isDemoPage) {
        navigate("/dashboard");
      }
    }
  }, [isDemoPage]);

  const percentage = (headLivePrice?.change / headLivePrice?.last_price) * 100;
  return (
    <div className="flex flex-col ">
      {loading && <Loader />}
      {!isDemoPage ? (
        <div
          style={{
            backgroundColor: "#F5F5F5",
            borderBottomWidth: 1,
            borderColor: "#E6E6E6",
          }}
        >
          <div className="flex flex-row justify-between items-center pl-[40px] pr-[40px] pt-[12px] pb-[12px]">
            <div className="cursor-pointer">
              <span
                onClick={() => {
                  navigate("/addChildAccount");
                }}
                style={{ fontSize: 12, fontWeight: "400", color: "#817E7E" }}
              >
                Add Child Account
              </span>
            </div>
            <div className="flex flex-row items-center ">
              <div className="mr-[7px]">
                <span
                  style={{ fontSize: 15, fontWeight: "400", color: "#817E7E" }}
                >
                  Master Account
                </span>
              </div>

              <div className="flex items-center justify-center ml-[8px] mr-[8px]  bg-gray-100">
                <button
                  className={`w-14 h-6 flex items-center rounded-full p-1 duration-300 ease-in-out`}
                  style={{ backgroundColor: isToggled ? "green" : "green  " }}
                  onClick={() => handleToggle()}
                >
                  <div
                    style={{ backgroundColor: "white" }}
                    className={` w-4 h-4  rounded-full shadow-md transform duration-300 ease-in-out ${
                      isToggled ? "translate-x-7" : "translate-x-1"
                    }`}
                  />
                </button>
              </div>
              <div className="mr-[21px]">
                <span
                  style={{ fontSize: 15, fontWeight: "400", color: "#817E7E" }}
                >
                  Child Account
                </span>
              </div>

              <div
                style={{
                  width: 164,
                  height: 24,
                  backgroundColor: "white",
                  borderRadius: 4,
                  borderWidth: 0.5,
                  borderColor: "#D9D9D9",
                }}
                className="flex items-centerbg-gray-100 "
              >
                <input
                  type="text"
                  style={{ width: "70%" }}
                  className="flex-grow px-3 py-2 text-gray-700 bg-transparent border-none focus:outline-none"
                  placeholder="821885- Rithik"
                />
                <button className="mr-[12px]">
                  <img
                    src={searchIcon}
                    alt="search icon"
                    className="w-[12px] h-[12px]"
                  />
                </button>
              </div>

              <div
                style={{
                  borderRadius: 4,
                  borderWidth: 0.5,
                  borderColor: "#D9D9D9",
                  fontSize: 12,
                  width: 125,
                  height: 25,
                }}
                className=" flex items-center ml-2 px-4 py-2 border rounded bg-gray-200"
              >
                Broker's Name
              </div>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}

      <div
        className="flex items-center justify-between w-full py-4 px-8 shadow"
        style={{ backgroundColor: "#F5F5F5" }}
      >
        {isDemoPage ? <Header /> : <></>}
        {isDemoPage ? (
          <div className="flex items-center space-x-6">
            <img src={stoxview} alt="site logo" className="h-6" />

            <NavLink to="/dashboard" className="text-gray-500">
              Dashboard
            </NavLink>

            <NavLink to="/charts" className="text-gray-500">
              Chart
            </NavLink>

            <NavLink to="/orders" className="text-gray-500">
              Orders
            </NavLink>

            <NavLink to="/position" className="text-gray-500">
              Positions
            </NavLink>
            <NavLink to="/holding"  className="text-gray-500">
            Holdings
          </NavLink>
            <NavLink to="/funds" className="text-gray-500">
              Funds
            </NavLink>

            <NavLink to="/optionchain" className="text-gray-500">
              Option chain
            </NavLink>
          </div>
        ) : (
          <div className="relative flex items-center space-x-6">
            <img src={stoxview} alt="site logo" className="h-6" />

            <NavLink to="/dashboard" className="text-gray-500">
              Dashboard
            </NavLink>

            <NavLink to="/brokerintegration" className="text-gray-500">
              Broker Integration
            </NavLink>

            <div
              onMouseEnter={() => setDropdownVisible(true)}
              //onClick={() => setDropdownVisible(true)}

              onMouseLeave={() => setDropdownVisible(false)}
              className=" flex items-center h-12"
            >
              <NavLink to="/scriptsetting" className="  text-gray-500 relative">
                <span>Script Setting</span>
                {dropdownVisible && (
                  <>
                    <div
                      style={{
                        backgroundColor: "white",
                        borderWidth: 1,
                        borderColor: "#E6E6E6",
                      }}
                      className=" absolute  w-4 h-4 left-[50%] border-gray-300  rotate-45 shadow-md"
                    ></div>
                    <ul
                      className="absolute top-full left-[-50%] mt-2 w-48 bg-white  border-gray-300 rounded shadow-md"
                      // onMouseEnter={() => setDropdownVisible(true)}
                      //onMouseLeave={() => setDropdownVisible(false)}
                      // onClick={() => setDropdownVisible(true)}
                      style={{ backgroundColor: "white" }}
                    >
                      <li
                        style={{
                          borderWidth: 1,
                          borderColor: "#E6E6E6",
                          fontSize: 14,
                        }}
                        className=" px-4 py-2 hover:bg-gray-100 cursor-pointer"
                      >
                        <button
                          onClick={() => {
                            setDropdownVisible(false);
                            setIsScriptOrBased(true);
                          }}
                        >
                          Script's List
                        </button>
                      </li>
                      <li
                        style={{
                          borderWidth: 1,
                          borderColor: "#E6E6E6",
                          fontSize: 14,
                        }}
                        className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                      >
                        <button
                          onClick={() => {
                            setDropdownVisible(false);
                            setIsScriptOrBased(false);
                          }}
                        >
                          Based on's List
                        </button>
                      </li>
                    </ul>
                  </>
                )}
              </NavLink>
            </div>

            <NavLink to="/charts" className="text-gray-500">
              Chart
            </NavLink>

            {/* <NavLink to="/orders" className="text-gray-500">
              Orders
            </NavLink> */}

            <div
              onMouseEnter={() => setOrderDropdownVisible(true)}
              //onClick={() => setDropdownVisible(true)}

              onMouseLeave={() => setOrderDropdownVisible(false)}
              className=" flex items-center h-12"
            >
              <NavLink to="/orders" className="  text-gray-500 relative">
                <span> Orders</span>
                {orderdropdownVisible && (
                  <>
                    <div
                      style={{
                        backgroundColor: "white",
                        borderWidth: 1,
                        borderColor: "#E6E6E6",
                      }}
                      className=" absolute  w-4 h-4 left-[50%] border-gray-300  rotate-45 shadow-md"
                    ></div>
                    <ul
                      className="absolute top-full left-[-50%] mt-2 w-48 bg-white  border-gray-300 rounded shadow-md"
                      // onMouseEnter={() => setDropdownVisible(true)}
                      //onMouseLeave={() => setDropdownVisible(false)}
                      // onClick={() => setDropdownVisible(true)}
                      style={{ backgroundColor: "white" }}
                    >
                      <li
                        style={{
                          borderWidth: 1,
                          borderColor: "#E6E6E6",
                          fontSize: 14,
                        }}
                        className=" px-4 py-2 hover:bg-gray-100 cursor-pointer"
                      >
                        <button
                          onClick={() => {
                            setIsMasterOrChildOrder(true);
                            setOrderDropdownVisible(false);
                            //setIsScriptOrBased(true);
                          }}
                        >
                          Master orders
                        </button>
                      </li>
                      <li
                        style={{
                          borderWidth: 1,
                          borderColor: "#E6E6E6",
                          fontSize: 14,
                        }}
                        className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                      >
                        <button
                          onClick={() => {
                            setIsMasterOrChildOrder(false);
                            setOrderDropdownVisible(false);
                            // setIsScriptOrBased(false);
                          }}
                        >
                          Child orders
                        </button>
                      </li>
                    </ul>
                  </>
                )}
              </NavLink>
            </div>

            {/* <NavLink to="/position" className="text-gray-500">
              Positions
            </NavLink> */}

            <div
              onMouseEnter={() => setPositionDropdownVisible(true)}
              //onClick={() => setDropdownVisible(true)}

              onMouseLeave={() => setPositionDropdownVisible(false)}
              className=" flex items-center h-12"
            >
              <NavLink to="/position" className="  text-gray-500 relative">
                <span> Positions</span>
                {positiondropdownVisible && (
                  <>
                    <div
                      style={{
                        backgroundColor: "white",
                        borderWidth: 1,
                        borderColor: "#E6E6E6",
                      }}
                      className=" absolute  w-4 h-4 left-[50%] border-gray-300  rotate-45 shadow-md"
                    ></div>
                    <ul
                      className="absolute top-full left-[-50%] mt-2 w-48 bg-white  border-gray-300 rounded shadow-md"
                      // onMouseEnter={() => setDropdownVisible(true)}
                      //onMouseLeave={() => setDropdownVisible(false)}
                      // onClick={() => setDropdownVisible(true)}
                      style={{ backgroundColor: "white" }}
                    >
                      <li
                        style={{
                          borderWidth: 1,
                          borderColor: "#E6E6E6",
                          fontSize: 14,
                        }}
                        className=" px-4 py-2 hover:bg-gray-100 cursor-pointer"
                      >
                        <button
                          onClick={() => {
                            setIsMasterOrChildPosition(true);
                            setPositionDropdownVisible(false);
                            //setIsScriptOrBased(true);
                          }}
                        >
                          Master Position
                        </button>
                      </li>
                      <li
                        style={{
                          borderWidth: 1,
                          borderColor: "#E6E6E6",
                          fontSize: 14,
                        }}
                        className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                      >
                        <button
                          onClick={() => {
                            setIsMasterOrChildPosition(false);
                            setPositionDropdownVisible(false);
                            //setIsScriptOrBased(false);
                          }}
                        >
                          Child Position
                        </button>
                      </li>
                    </ul>
                  </>
                )}
              </NavLink>
            </div>
            <NavLink to="/holding"  className="text-gray-500">
            Holdings
          </NavLink>
            {/* <NavLink to="/funds" className="text-gray-500">
              Funds
            </NavLink> */}
            <NavLink to="/optionchain" className="text-gray-500">
              Option chain
            </NavLink>
          </div>
        )}
        <div className="flex relative items-center space-x-4">
          {/* <div className="bg-customGreen text-white px-4 py-1 rounded-full">
          LIVE
        </div> */}

          {/* <ToggleSwitch /> */}
          <div className="flex items-center justify-center ml-[8px] mr-[8px]  bg-gray-100">
            <button
              className={`w-20 h-8 flex items-center relative rounded-full p-1 duration-300 ease-in-out`}
              style={{ backgroundColor: isDemoPage ? "red" : "green" }}
              onClick={() => handleLiveToggle()}
            >
              <div
                style={{ backgroundColor: "white" }}
                className={` w-5 h-5  rounded-full shadow-md transform duration-300 ease-in-out ${
                  isDemoPage ? "translate-x-12" : "translate-x-1"
                }`}
              />
              <h2
                style={
                  !isDemoPage
                    ? {
                        color: "white",
                        position: "absolute",
                        top: "20%",
                        right: 10,
                        fontSize: 14,
                        fontWeight: "400",
                      }
                    : {
                        color: "white",
                        position: "absolute",
                        top: "25%",
                        left: 10,
                        fontSize: 12,
                        fontWeight: "400",
                      }
                }
              >
                {isDemoPage ? "Demo" : "Live"}
              </h2>
            </button>
          </div>

          <div
            className="flex flex-row cursor-pointer items-center"
            onClick={() => openProfileOption()}
          >
            <div
              className="bg-customGreen rounded-full flex items-center justify-center"
              style={{ width: "32px", height: "32px" }}
            >
              <img
                src={userIcon}
                alt="User Icon"
                style={{ width: "12px", height: "12px" }}
              />
            </div>

            <div
              className="text-gray-500"
              style={{ marginLeft: "8px", marginRight: 8 }}
            >
              Hi {userprofile?.fname}
            </div>
            <img
              src={down}
              alt="up icon"
              style={{ width: "8px", height: "5.16px" }}
              onClick={() => openProfileOption()}
            />
          </div>
          {profileOption && (
            <div
              style={{
                backgroundColor: "white",
                top: 44,
                right: 40,
                borderWidth: 0.5,
                borderColor: "#D9D9D9",
                width: 198,
                borderRadius: 4,
              }}
              className="absolute z-10"
            >
              <div className="flex flex-row items-center pt-[8px] pb-[8px] pl-[21px] pr-[21px]">
                <img src={lock} alt="" style={{ width: 9, height: 12 }} />
                <button
                  onClick={handleClickOutside}
                  style={{ fontSize: 12, color: "#817E7E", marginLeft: 16 }}
                >
                  Lock Screen
                </button>
              </div>

              <div style={{ borderWidth: 0.5, borderColor: "#D9D9D9" }} />

              <div className="flex flex-row items-center pt-[8px] pb-[8px] pl-[21px] pr-[21px]">
                <img src={copy} alt="" style={{ width: 9, height: 12 }} />
                <button
                  onClick={() =>
                    handleCopyClick(
                      Object.keys(userprofile).length !== 0
                        ? userprofile.digital_key.substring(0, 20)
                        : ""
                    )
                  }
                  style={{
                    fontSize: 12,
                    color: "#817E7E",
                    marginLeft: 16,
                    width: "100%",
                  }}
                >
                  {Object.keys(userprofile).length !== 0
                    ? userprofile.digital_key.substring(0, 20)
                    : ""}
                </button>
              </div>

              <div style={{ borderWidth: 0.5, borderColor: "#D9D9D9" }} />

              <div className="flex flex-row items-center pt-[8px] pb-[8px] pl-[21px] pr-[21px]">
                <img src={key} alt="" style={{ width: 9, height: 12 }} />
                <button
                  onClick={handleClickOutside}
                  style={{ fontSize: 12, color: "#817E7E", marginLeft: 16 }}
                >
                  Change Password
                </button>
              </div>

              <div style={{ borderWidth: 0.5, borderColor: "#D9D9D9" }} />

              <div className="flex flex-row items-center pt-[8px] pb-[8px] pl-[21px] pr-[21px]">
                <img src={logout} alt="" style={{ width: 9, height: 12 }} />
                <button
                  onClick={() => logoutCall()}
                  style={{ fontSize: 12, color: "#817E7E", marginLeft: 16 }}
                >
                  Log Out
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Navbar;
