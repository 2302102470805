import React, { useState, useEffect, useContext } from "react";
import { toast } from "react-toastify";
import Loader from "../loader/Loader";
import MyContext from "../../context/MyContext";

function CreateChild({ getAccountList, getDashboardCall }) {
  const { setLoading, loading } = useContext(MyContext);
  const [formData, setFormData] = useState({
    childname: "",
    email: "",
    password: "",
    mul: "",
    validity: "",
    username: "",
  });
  const [isToggled, setIsToggled] = useState(false);
  const [childName, setChildName] = useState("");
  const [username, setusername] = useState("");
  const [email, setemail] = useState("");
  const [password, setpassword] = useState("");
  const [validity, setvalidity] = useState("");
  const [mulqty, setmulqty] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Form submitted:", formData);
  };

  const handleChangeInput = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const createChildAccount = async () => {
    setLoading(true);
    console.log("create account data : ", formData);
    const sessionID = JSON.parse(localStorage.getItem("sessionID"));
    const body = {
      username: formData.username,
      password: formData.password,
      email: formData.email,
      first_name: formData.childname,
      validity: formData.validity,
      mul: formData.mul,
    };
    await fetch("https://app.stoxviews.com/api/addchild", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionID}`,
      },
      body: JSON.stringify(body),
    })
      .then((res) => res.json())
      .then((value) => {
        console.log("Creating child account result : ", value);
        if (value.status) {
          setLoading(false);
          toast.success("Child account created successfully", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setFormData({
            childname: "",
            email: "",
            password: "",
            mul: "",
            validity: "",
            username: "",
          });
          getAccountList();
          getDashboardCall();
        } else {
          setLoading(false);
          toast.success(`${value.message}`, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setFormData({
            childname: "",
            email: "",
            password: "",
            mul: "",
            validity: "",
            username: "",
          });
        }
      })
      .catch((err) => {
        toast.error(`Something went wrong`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setLoading(false);
        console.log("Error : ", err);
      });
  };

  return (
    <div
      className="mr-[10px] p-[10px]"
      style={{
        //width: "30%",
        height: "85%",
        // borderWidth: 1,
        // borderColor: "#e6e6e6",
      }}
    >
      {loading && <Loader />}
      {/* <div className="flex flex-row items-center justify-center mb-[10px] mt-[10px]">
        <div className="mr-[7px]">
          <span style={{ fontSize: 15, fontWeight: "400", color: "#817E7E" }}>
            Integrate Child
          </span>
        </div>

        <div className="flex items-center justify-center ml-[8px] mr-[8px]  bg-gray-100">
          <button
            className={`w-14 h-6 flex items-center rounded-full p-1 duration-300 ease-in-out`}
            style={{ backgroundColor: isToggled ? "green" : "green  " }}
            onClick={handleToggle}
          >
            <div
              style={{ backgroundColor: "white" }}
              className={` w-4 h-4  rounded-full shadow-md transform duration-300 ease-in-out ${
                isToggled ? "translate-x-7" : "translate-x-1"
              }`}
            />
          </button>
        </div>
        <div className="mr-[21px]">
          <span style={{ fontSize: 15, fontWeight: "400", color: "#817E7E" }}>
            Create Child
          </span>
        </div>
      </div> */}

      <div>
        <div className="flex flex-col mb-[5px]">
          <label
            htmlFor="name"
            className="mb-[5px]"
            style={{ fontSize: 14, color: "#817e7" }}
          >
            Child's Name
          </label>
          <input
            type="text"
            id="name"
            name="childname"
            placeholder="Enter your child's name"
            value={formData.childname}
            onChange={handleChangeInput}
            required
            style={{
              padding: "10px 5px",
              borderRadius: 4,
              borderWidth: 1,
              borderColor: "#817e7e",
              backgroundColor: "#ffffff",
            }}
          />
        </div>
        <div className="flex flex-col mb-[5px]">
          <label
            htmlFor="username"
            className="mb-[5px]"
            style={{ fontSize: 14, color: "#817e7" }}
          >
            Username
          </label>
          <input
            type="text"
            id="username"
            name="username"
            placeholder="Enter your username"
            value={formData.username}
            onChange={handleChangeInput}
            required
            style={{
              padding: "10px 5px",
              borderRadius: 4,
              borderWidth: 1,
              borderColor: "#817e7e",
              backgroundColor: "#ffffff",
            }}
          />
        </div>

        <div className="flex flex-col mb-[5px]">
          <label
            htmlFor="email"
            className="mb-[5px]"
            style={{ fontSize: 14, color: "#817e7" }}
          >
            Email Address
          </label>
          <input
            type="email"
            id="email"
            name="email"
            placeholder="Enter your email"
            value={formData.email}
            onChange={handleChangeInput}
            required
            style={{
              padding: "10px 5px",
              borderRadius: 4,
              borderWidth: 1,
              borderColor: "#817e7e",
              backgroundColor: "#ffffff",
            }}
          />
        </div>

        <div className="flex flex-col mb-[5px]">
          <label
            htmlFor="password"
            className="mb-[5px]"
            style={{ fontSize: 14, color: "#817e7" }}
          >
            Password
          </label>
          <input
            type="password"
            id="password"
            name="password"
            value={formData.password}
            placeholder="Enter your password"
            onChange={handleChangeInput}
            required
            style={{
              padding: "10px 5px",
              borderRadius: 4,
              borderWidth: 1,
              borderColor: "#817e7e",
              backgroundColor: "#ffffff",
            }}
          />
        </div>

        <div className="flex flex-row justify-between items-center">
          <div className="flex flex-col mb-[5px]" style={{ width: "45%" }}>
            <label
              htmlFor="validity"
              className="mb-[5px]"
              style={{ fontSize: 14, color: "#817e7" }}
            >
              Validity
            </label>
            <input
              type="date"
              id="validity"
              name="validity"
              value={formData.validity}
              onChange={handleChangeInput}
              required
              style={{
                padding: "10px 5px",
                borderRadius: 4,
                borderWidth: 1,
                borderColor: "#817e7e",
                backgroundColor: "#ffffff",
              }}
            />
          </div>

          <div className="flex flex-col mb-[5px]" style={{ width: "45%" }}>
            <label
              htmlFor="validity"
              className="mb-[5px]"
              style={{ fontSize: 14, color: "#817e7" }}
            >
              Multipyer (QTY)
            </label>
            <input
              required
              type="number"
              min="1"
              name="mul"
              placeholder="Quantity"
              value={formData.mul}
              onChange={handleChangeInput}
              style={{
                padding: "10px 5px",
                borderRadius: 4,
                borderWidth: 1,
                borderColor: "#817e7e",
                backgroundColor: "#ffffff",
              }}
            />
          </div>
        </div>

        <div className="flex items-center justify-end">
          <button
            // type="submit"
            onClick={createChildAccount}
            className="flex items-center justify-center mt-[8px]"
            style={{
              padding: "5px 11px 4px 12px",
              backgroundColor: "#d4edda",
              borderRadius: 2,
            }}
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
}

export default CreateChild;
