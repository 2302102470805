import React, { useEffect, useContext, useState } from "react";
import Layout from "../../components/layout/Layout";
import MyContext from "../../context/MyContext";
import DownloadIcon from "../../assets/icons/download.png";
import selectIcon from "../../assets/icons/select.png";
import Loader from "../../components/loader/Loader";
import "../../App.css";
import BuyAndSellModal from "../../components/modals/BuyAndSellModal";
function Positions() {
  const {
    setWatchListShown,
    setPageName,
    loading,
    setLoading,
    positions,
    setPositions,
    getPosition,
    setBopen,
    setBuyAndSellAction,
    isToggled,
    setIsToggled,
    setBuyAndSellTradingSymbol,
    setBuyAndSellExchange,
    setChartToken,
    setBuyAndSellExchangeToken,
    qty,
    setQty,
    setBuyAndSellModalLivePrice,
    isDemoPage,
    isMasterOrChildPosition,
  } = useContext(MyContext);

  const [positionStatusCount, setPositionStatusCount] = useState([
    { status: "Stocks", value: positions.filter(item=>item.instrument__segment==="EQ").length, isSelected: false },
    { status: "FUT", value: positions.filter(item=>item.instrument__segment==="FUTSTK").length, isSelected: false },
    { status: "OPT", value: positions.filter(item=>item.instrument__segment==="OPTSTK").length, isSelected: false },
    { status: "MCX", value: positions.filter(item=>item.instrument__segment==="MCX").length, isSelected: false },
    { status: "CDS", value: positions.filter(item=>item.instrument__segment==="CDS").length, isSelected: false },
  ]);

  const [isAllSelected, setIsAllSelected] = useState(true);
  const [isToggledTodayOverAll, setIsToggledTodayOverAll] = useState(false);
  const [totalPNL, setTotalPNL] = useState(0);
  const [totalLTP, setTotalLTP] = useState(0);

  const handleToggle = () => {
    setIsToggledTodayOverAll(!isToggledTodayOverAll);
  };

  const statusClasses = {
    COMPLETED: { bgcolor: "#D4EDDA", textColor: "#155724" },
    REJECTED: { bgcolor: "#F8D7DA", textColor: "#721C24" },
    PENDING: { bgcolor: "#B6E0FF", textColor: "#013052" },
  };

  const statusBgColor = (status) => {
    let bg = "";
    switch (status) {
      case "CNC":
        bg = statusClasses.COMPLETED.bgcolor;
        break;

      case "NRML":
        bg = statusClasses.REJECTED.bgcolor;
        break;

      case "MIS":
        bg = statusClasses.PENDING.bgcolor;
        break;
    }
    return bg;
  };

  const statusTextColor = (status) => {
    let color = "";
    switch (status) {
      case "NSE":
        color = statusClasses.COMPLETED.textColor;
        break;

      case "NRML":
        color = statusClasses.REJECTED.textColor;
        break;

      case "MIS":
        color = statusClasses.PENDING.textColor;
        break;
    }
    return color;
  };

  useEffect(() => {
    // setWatchListShown(true);
    // getPosition();
    let newPNL = 0;
    let newLtp = 0;
    positions?.forEach((position) => {
      const Ltp = position?.last_traded_price;
      const BuyAT = position?.buy_qty * position?.buy_avg;
      const SELLAT = position?.sell_qty * position?.sell_avg;
      const old_profit = BuyAT - SELLAT;
      const NbuyAt = position?.buy_qty * Ltp;
      const NSellAt = position?.sell_qty * Ltp;
      const New_Profit = NbuyAt - NSellAt;
      const PNL = New_Profit - old_profit;
      newPNL = newPNL + PNL;
      newLtp = newLtp + Ltp;
      // if (!isNaN(PNL)) {
      // }

      if (!isNaN(Ltp)) {
        // setTotalLTP(totalLTP + Ltp);
      }
    });
    setTotalLTP(newLtp);
    setTotalPNL(newPNL);
    setPageName("position");
  }, [positions]);

  useEffect(() => {
    isDemoPage && getPosition();
  }, []);

  const onMouseCallOverPositionInstrument = (index) => {
    setPositions(
      positions.map((it, idx) => {
        if (index === idx) {
          if (it.isAddOrExitBtnOpen) {
            it.isAddOrExitBtnOpen = false;
          } else {
            it.isAddOrExitBtnOpen = true;
          }
        } else {
          it.isAddOrExitBtnOpen = false;
        }
        return it;
      })
    );
  };

  const onMouseCallOutPositionInstrument = (index) => {
    setPositions(
      positions.map((it, idx) => {
        it.isAddOrExitBtnOpen = false;
        return it;
      })
    );
  };

  const onOpenAddModal = (
    buy_qty,
    sell_qty,
    trading_symbol,
    exchange_token,
    instrument__exchange,
    livePrice
  ) => {
    setBopen(true);
    setBuyAndSellTradingSymbol(trading_symbol);
    setBuyAndSellExchangeToken(exchange_token);
    setBuyAndSellExchange(instrument__exchange);
    setChartToken(exchange_token);
    setBuyAndSellModalLivePrice(livePrice);
    setQty("");
    if (buy_qty > sell_qty) {
      setBuyAndSellAction("buy");
      setIsToggled(false);
    } else {
      setBuyAndSellAction("sell");
      setIsToggled(true);
    }
  };

  const onOpenExitModal = (
    buy_qty,
    sell_qty,
    trading_symbol,
    exchange_token,
    instrument__exchange,
    livePrice
  ) => {
    setBopen(true);
    setBuyAndSellTradingSymbol(trading_symbol);
    setBuyAndSellExchangeToken(exchange_token);
    setBuyAndSellExchange(instrument__exchange);
    setChartToken(exchange_token);
    setBuyAndSellModalLivePrice(livePrice);
    if (buy_qty > sell_qty) {
      setQty(buy_qty - sell_qty);
      setBuyAndSellAction("sell");
      setIsToggled(true);
    } else {
      setQty(sell_qty - buy_qty);
      setBuyAndSellAction("buy");
      setIsToggled(false);
    }
  };

  const positionType = (type) => {
    if (type === "Intraday")
      return { type: "MIS", color: "#013052", backgroundColor: "#0D99FF" };
    else if (type === "Delivery")
      return { type: "NRML", color: "#155724", backgroundColor: "#D4EDDA" };
  };

  const isToday = (dateString) => {
    const inputDate = new Date(dateString);

    const today = new Date();
    today.setHours(0, 0, 0, 0);

    const tomorrow = new Date(today);
    tomorrow.setDate(today.getDate() + 1);

    return inputDate >= today && inputDate < tomorrow;
  };

  // function filterList(dataList, showTodayOnly) {
  //   if (showTodayOnly) {
  //     return dataList.filter((item) => isToday(item.createdAt));
  //   }
  //   return dataList;
  // }

  const filterPositions = () => {
    let updatedPosition = [...positions];

    return !isToggledTodayOverAll
      ? updatedPosition.filter((item) => isToday(item.odate))
      : updatedPosition;
  };

  return (
    <Layout>
      {isDemoPage ? (
        <div className="p-4">
          {loading && <Loader />}
          <div className="flex flex-row items-center justify-between mb-4">
            <div className="flex  items-center ">
              <h1 className="text-xl font-bold mr-[36px]">Positions</h1>
              <div className="flex space-x-1">
                <button
                  onClick={() => {
                    setPositionStatusCount(
                      positionStatusCount.map((it, idx) => {
                        it.isSelected = false;

                        return it;
                      })
                    );
                    setIsAllSelected(true);
                  }}
                  className="px-3 py-1 border  bg-green-100 flex flex-row items-center"
                  style={{
                    fontSize: 14,
                    color: isAllSelected ? "#155724" : "#817E7E",
                    backgroundColor: isAllSelected ? "#D4EDDA" : "#FFFFFF",
                  }}
                >
                  ALL | {positions.length}
                </button>

                {positionStatusCount.map((item, index) => {
                  return (
                    <button
                      className="px-3 py-1 border"
                      key={index}
                      style={{ borderColor: "#817E7E" }}
                      onClick={() => {
                        setIsAllSelected(false);
                        setPositionStatusCount(
                          positionStatusCount.map((it, idx) => {
                            if (index === idx) {
                              it.isSelected = true;
                            } else {
                              it.isSelected = false;
                            }
                            return it;
                          })
                        );
                      }}
                      style={{
                        fontSize: 12,
                        color: item.isSelected ? "#155724" : "#817E7E",
                        backgroundColor: item.isSelected
                          ? "#D4EDDA"
                          : "#FFFFFF",
                      }}
                    >
                      {item.status} | {item.value}
                    </button>
                  );
                })}
              </div>
            </div>

            <div className="flex flex-row items-center z-0">
              <div className="mr-[7px]">
                <span
                  style={{ fontSize: 12, fontWeight: "500", color: "#817E7E" }}
                >
                  Today
                </span>
              </div>

              <div className="flex items-center justify-center ml-[8px] mr-[8px]  bg-gray-100">
                <button
                  className={`w-14 h-6 flex items-center rounded-full p-1 duration-300 ease-in-out`}
                  style={{
                    backgroundColor: isToggledTodayOverAll
                      ? "green"
                      : "green  ",
                  }}
                  onClick={() => handleToggle()}
                >
                  <div
                    style={{ backgroundColor: "white" }}
                    className={` w-4 h-4  rounded-full shadow-md transform duration-300 ease-in-out ${
                      isToggledTodayOverAll ? "translate-x-7" : "translate-x-1"
                    }`}
                  />
                </button>
              </div>
              <div className="mr-[21px]">
                <span
                  style={{ fontSize: 12, fontWeight: "500", color: "#817E7E" }}
                >
                  Overall
                </span>
              </div>
            </div>

            <div className="mr-[16PX]">
              <button>
                <img
                  src={DownloadIcon}
                  alt="download"
                  style={{ width: 14, height: 16 }}
                />
              </button>
            </div>
          </div>

          <table className="min-w-full bg-white orderTable">
            <thead className="orderTable">
              <tr>
                <th className="px-4 py-2">
                  <img
                    src={selectIcon}
                    alt="select option"
                    style={{ width: 16, height: 16 }}
                  />
                </th>
                <th className="px-4 py-2 border">Type</th>
                <th className="px-4 py-2 border">Instrument</th>
                <th className="px-4 py-2 border">Buy Qty.</th>
                <th className="px-4 py-2 border">Sell Qty.</th>
                <th className="px-4 py-2 border">Buy Avg</th>
                <th className="px-4 py-2 border">Sell Avg</th>
                <th className="px-4 py-2 border">PNL</th>
                <th className="px-4 py-2 border">LTP</th>
              </tr>
            </thead>
            <tbody>
              {filterPositions()?.map((position, index) => {
                console.log("positions : ", position);
                const Ltp = position?.last_traded_price;
                const BuyAT = position?.buy_qty * position?.buy_avg;
                const SELLAT = position?.sell_qty * position?.sell_avg;
                const old_profit = BuyAT - SELLAT;
                const NbuyAt = position?.buy_qty * Ltp;
                const NSellAt = position?.sell_qty * Ltp;
                const New_Profit = NbuyAt - NSellAt;
                const PNL = New_Profit - old_profit;
                return (
                  <tr key={index}>
                    <td className="px-4 py-2 border orderTabletd">
                      <img
                        src={selectIcon}
                        alt="select option"
                        style={{ width: 16, height: 16 }}
                      />
                    </td>
                    <td className="px-4 py-2 border orderTabletd">
                      <button
                        style={{
                          fontSize: 10,
                          paddingLeft: 4,
                          paddingRight: 4,
                          height: 20,
                          borderRadius: 3,
                          color: positionType(position.ptype).color,
                          backgroundColor: positionType(position.ptype)
                            .backgroundColor,
                        }}
                      >
                        {/* {`${position.ptype==="Intraday"?"MIS":"NRML"} `} */}
                        {positionType(position.ptype).type}
                      </button>
                    </td>

                    <td
                      className="px-4 py-2 border relative orderTabletd"
                      style={{
                        fontSize: 14,
                        color: "#363636",
                        fontWeight: "700",
                      }}
                      onMouseOver={() => {
                        onMouseCallOverPositionInstrument(index);
                      }}
                      onMouseOut={() => {
                        onMouseCallOutPositionInstrument(index);
                      }}
                    >
                      <div className="relative">
                        <div>{position.instrument__trading_symbol}</div>

                        {position.isAddOrExitBtnOpen && (
                          <div className="addorexit">
                            <button
                              className="addBtn"
                              onClick={() =>
                                onOpenAddModal(
                                  position.buy_qty,
                                  position.sell_qty,
                                  position.instrument__trading_symbol,
                                  position.instrument__exchange_token,
                                  position.instrument__exchange,
                                  position.last_traded_price
                                )
                              }
                            >
                              <p>Add</p>
                            </button>
                            <button
                              className="exitBtn"
                              onClick={() =>
                                onOpenExitModal(
                                  position.buy_qty,
                                  position.sell_qty,
                                  position.instrument__trading_symbol,
                                  position.instrument__exchange_token,
                                  position.instrument__exchange,
                                  position.last_traded_price
                                )
                              }
                            >
                              <p>Exit</p>
                            </button>
                          </div>
                        )}
                      </div>
                    </td>
                    <td className="px-4 py-2 border orderTabletd">
                      {position.buy_qty}
                    </td>
                    <td className="px-4 py-2 border orderTabletd">
                      {position.sell_qty}
                    </td>
                    <td className="px-4 py-2 border orderTabletd">
                      {position.buy_avg}
                    </td>
                    <td className="px-4 py-2 border orderTabletd">
                      {position.sell_avg}
                    </td>
                    <td
                      className="px-4 py-2 border orderTabletd"
                      style={{
                        color: PNL >= 0 ? "#39A245" : "#DF2123",
                      }}
                    >
                      {/* {position.LTP} */}
                      {isNaN(PNL) ? 0 : PNL.toFixed(2)}
                    </td>
                    <td
                      className="px-4 py-2 border orderTabletd"
                      style={{
                        color: Ltp >= 0 ? "#39A245" : "#DF2123",
                      }}
                    >
                      {/* {position.PL} */}
                      {isNaN(Ltp) ? 0.0 : Ltp.toFixed(2)}
                    </td>
                    {/* <td
                    className="px-4 py-2 border orderTabletd"
                    // style={{
                    //   color:
                    //     parseInt(position.Netchg) > 0 ? "#39A245" : "#DF2123",
                    // }}
                  >
                    {position?.Netchg}
                  </td> */}
                  </tr>
                );
              })}
              <tr>
                <td className="px-4 py-2 border orderTabletd" colSpan="6"></td>
                <td className="px-4 py-2  orderTabletd">Total</td>
                <td className="px-4 py-2  orderTabletd">
                  {isNaN(totalPNL) ? 0 : totalPNL.toFixed(2)}
                </td>
                <td className="px-4 py-2  orderTabletd">
                  {isNaN(totalLTP) ? 0 : totalLTP.toFixed(2)}
                </td>
                {/* <td className="px-4 py-2  orderTabletd"></td> */}
              </tr>
            </tbody>
          </table>
        </div>
      ) : isMasterOrChildPosition ? (
        <div className="p-4">
          {loading && <Loader />}
          <div className="flex flex-row items-center justify-between mb-4">
            <div className="flex  items-center ">
              <h1 className="text-xl font-bold mr-[36px]">Positions</h1>
              <div className="flex space-x-1">
                <button
                  onClick={() => {
                    setPositionStatusCount(
                      positionStatusCount.map((it, idx) => {
                        it.isSelected = false;

                        return it;
                      })
                    );
                    setIsAllSelected(true);
                  }}
                  className="px-3 py-1 border  bg-green-100 flex flex-row items-center"
                  style={{
                    fontSize: 14,
                    color: isAllSelected ? "#155724" : "#817E7E",
                    backgroundColor: isAllSelected ? "#D4EDDA" : "#FFFFFF",
                  }}
                >
                  ALL | 25
                </button>

                {positionStatusCount.map((item, index) => {
                  return (
                    <button
                      className="px-3 py-1 border"
                      key={index}
                      style={{ borderColor: "#817E7E" }}
                      onClick={() => {
                        setIsAllSelected(false);
                        setPositionStatusCount(
                          positionStatusCount.map((it, idx) => {
                            if (index === idx) {
                              it.isSelected = true;
                            } else {
                              it.isSelected = false;
                            }
                            return it;
                          })
                        );
                      }}
                      style={{
                        fontSize: 12,
                        color: item.isSelected ? "#155724" : "#817E7E",
                        backgroundColor: item.isSelected
                          ? "#D4EDDA"
                          : "#FFFFFF",
                      }}
                    >
                      {item.status} | {item.value}
                    </button>
                  );
                })}
              </div>
            </div>

            <div className="flex flex-row items-center z-0">
              <div className="mr-[7px]">
                <span
                  style={{ fontSize: 12, fontWeight: "500", color: "#817E7E" }}
                >
                  Today
                </span>
              </div>

              <div className="flex items-center justify-center ml-[8px] mr-[8px]  bg-gray-100">
                <button
                  className={`w-14 h-6 flex items-center rounded-full p-1 duration-300 ease-in-out`}
                  style={{
                    backgroundColor: isToggledTodayOverAll
                      ? "green"
                      : "green  ",
                  }}
                  onClick={() => handleToggle()}
                >
                  <div
                    style={{ backgroundColor: "white" }}
                    className={` w-4 h-4  rounded-full shadow-md transform duration-300 ease-in-out ${
                      isToggledTodayOverAll ? "translate-x-7" : "translate-x-1"
                    }`}
                  />
                </button>
              </div>
              <div className="mr-[21px]">
                <span
                  style={{ fontSize: 12, fontWeight: "500", color: "#817E7E" }}
                >
                  Overall
                </span>
              </div>
            </div>

            <div className="mr-[16PX]">
              <button>
                <img
                  src={DownloadIcon}
                  alt="download"
                  style={{ width: 14, height: 16 }}
                />
              </button>
            </div>
          </div>

          <table className="min-w-full bg-white orderTable">
            <thead className="orderTable">
              <tr>
                <th className="px-4 py-2">
                  <img
                    src={selectIcon}
                    alt="select option"
                    style={{ width: 16, height: 16 }}
                  />
                </th>
                {/* <th className="px-4 py-2 border">Type</th>
                <th className="px-4 py-2 border">Instrument</th>
                <th className="px-4 py-2 border">Buy Qty.</th>
                <th className="px-4 py-2 border">Sell Qty.</th>
                <th className="px-4 py-2 border">Buy Avg</th>
                <th className="px-4 py-2 border">Sell Avg</th>
                <th className="px-4 py-2 border">PNL</th>
                <th className="px-4 py-2 border">LTP</th> */}
                <th className="px-4 py-2 border">Type</th>
                <th className="px-4 py-2 border">Instrument</th>
                <th className="px-4 py-2 border">Buy Qty.</th>
                <th className="px-4 py-2 border">Sell Qty.</th>
                <th className="px-4 py-2 border">Buy Avg</th>
                <th className="px-4 py-2 border">Sell Avg</th>
                <th className="px-4 py-2 border">PNL</th>
                <th className="px-4 py-2 border">Ltp</th>
                {/* <th className="px-4 py-2 border">Net</th> */}

                {/* <th className="px-4 py-2 border">Action</th> */}
              </tr>
            </thead>
            <tbody>
              {positions?.map((position, index) => {
                // console.log("positions : ", position);
                const Ltp = position?.last_traded_price;
                const BuyAT = position?.buy_qty * position?.buy_avg;
                const SELLAT = position?.sell_qty * position?.sell_avg;
                const old_profit = BuyAT - SELLAT;
                const NbuyAt = position?.buy_qty * Ltp;
                const NSellAt = position?.sell_qty * Ltp;
                const New_Profit = NbuyAt - NSellAt;
                const PNL = New_Profit - old_profit;
                return (
                  <tr key={index}>
                    <td className="px-4 py-2 border orderTabletd">
                      <img
                        src={selectIcon}
                        alt="select option"
                        style={{ width: 16, height: 16 }}
                      />
                    </td>
                    <td className="px-4 py-2 border orderTabletd">
                      <button
                        style={{
                          fontSize: 10,
                          paddingLeft: 4,
                          paddingRight: 4,
                          height: 20,
                          borderRadius: 3,
                          color: positionType(position.ptype).color,
                          backgroundColor: positionType(position.ptype)
                            .backgroundColor,
                        }}
                      >
                        {/* {`${position.ptype}`} */}
                        {positionType(position.ptype).type}
                      </button>
                    </td>

                    <td
                      className="px-4 py-2 border relative orderTabletd"
                      style={{
                        fontSize: 14,
                        color: "#363636",
                        fontWeight: "700",
                      }}
                      onMouseOver={() => {
                        onMouseCallOverPositionInstrument(index);
                      }}
                      onMouseOut={() => {
                        onMouseCallOutPositionInstrument(index);
                      }}
                    >
                      <div className="relative">
                        <div>{position.instrument__trading_symbol}</div>

                        {position.isAddOrExitBtnOpen && (
                          <div className="addorexit">
                            <button
                              className="addBtn"
                              onClick={() =>
                                onOpenAddModal(
                                  position.buy_qty,
                                  position.sell_qty,
                                  position.instrument__trading_symbol,
                                  position.instrument__exchange_token,
                                  position.instrument__exchange,
                                  position.last_traded_price
                                )
                              }
                            >
                              <p>Add</p>
                            </button>
                            <button
                              className="exitBtn"
                              onClick={() =>
                                onOpenExitModal(
                                  position.buy_qty,
                                  position.sell_qty,
                                  position.instrument__trading_symbol,
                                  position.instrument__exchange_token,
                                  position.instrument__exchange,
                                  position.last_traded_price
                                )
                              }
                            >
                              <p>Exit</p>
                            </button>
                          </div>
                        )}
                      </div>
                    </td>
                    <td className="px-4 py-2 border orderTabletd">
                      {position.buy_qty}
                    </td>
                    <td className="px-4 py-2 border orderTabletd">
                      {position.sell_qty}
                    </td>
                    <td className="px-4 py-2 border orderTabletd">
                      {position.buy_avg}
                    </td>
                    <td className="px-4 py-2 border orderTabletd">
                      {position.sell_avg}
                    </td>
                    <td
                      className="px-4 py-2 border orderTabletd"
                      style={{
                        color: PNL >= 0 ? "#39A245" : "#DF2123",
                      }}
                    >
                      {/* {position.LTP} */}
                      {isNaN(PNL) ? 0 : PNL.toFixed(2)}
                    </td>
                    <td
                      className="px-4 py-2 border orderTabletd"
                      style={{
                        color: Ltp >= 0 ? "#39A245" : "#DF2123",
                      }}
                    >
                      {/* {position.PL} */}
                      {isNaN(Ltp) ? 0.0 : Ltp.toFixed(2)}
                    </td>

                    {/* <td
                    className="px-4 py-2 border orderTabletd"
                    // style={{
                    //   color:
                    //     parseInt(position.Netchg) > 0 ? "#39A245" : "#DF2123",
                    // }}
                  >
                    {position?.Netchg}
                  </td> */}
                  </tr>
                );
              })}
              <tr>
                <td className="px-4 py-2 border orderTabletd" colSpan="6"></td>
                <td className="px-4 py-2  orderTabletd">Total</td>
                <td className="px-4 py-2  orderTabletd">
                  {isNaN(totalPNL) ? 0 : totalPNL.toFixed(2)}
                </td>
                <td className="px-4 py-2  orderTabletd">
                  {isNaN(totalLTP) ? 0 : totalLTP.toFixed(2)}
                </td>
                <td className="px-4 py-2  orderTabletd"></td>
              </tr>
            </tbody>
          </table>
        </div>
      ) : (
        <div className="p-4">
          {loading && <Loader />}
          <div className="flex flex-row items-center justify-between mb-4">
            <div className="flex  items-center ">
              <h1 className="text-xl font-bold mr-[36px]">Positions</h1>
              <div className="flex space-x-1">
                <button
                  onClick={() => {
                    setPositionStatusCount(
                      positionStatusCount.map((it, idx) => {
                        it.isSelected = false;

                        return it;
                      })
                    );
                    setIsAllSelected(true);
                  }}
                  className="px-3 py-1 border  bg-green-100 flex flex-row items-center"
                  style={{
                    fontSize: 14,
                    color: isAllSelected ? "#155724" : "#817E7E",
                    backgroundColor: isAllSelected ? "#D4EDDA" : "#FFFFFF",
                  }}
                >
                  ALL | 25
                </button>

                {positionStatusCount.map((item, index) => {
                  return (
                    <button
                      className="px-3 py-1 border"
                      key={index}
                      style={{ borderColor: "#817E7E" }}
                      onClick={() => {
                        setIsAllSelected(false);
                        setPositionStatusCount(
                          positionStatusCount.map((it, idx) => {
                            if (index === idx) {
                              it.isSelected = true;
                            } else {
                              it.isSelected = false;
                            }
                            return it;
                          })
                        );
                      }}
                      style={{
                        fontSize: 12,
                        color: item.isSelected ? "#155724" : "#817E7E",
                        backgroundColor: item.isSelected
                          ? "#D4EDDA"
                          : "#FFFFFF",
                      }}
                    >
                      {item.status} | {item.value}
                    </button>
                  );
                })}
              </div>
            </div>

            <div className="flex flex-row items-center z-0">
              <div className="mr-[7px]">
                <span
                  style={{ fontSize: 12, fontWeight: "500", color: "#817E7E" }}
                >
                  Today
                </span>
              </div>

              <div className="flex items-center justify-center ml-[8px] mr-[8px]  bg-gray-100">
                <button
                  className={`w-14 h-6 flex items-center rounded-full p-1 duration-300 ease-in-out`}
                  style={{
                    backgroundColor: isToggledTodayOverAll
                      ? "green"
                      : "green  ",
                  }}
                  onClick={() => handleToggle()}
                >
                  <div
                    style={{ backgroundColor: "white" }}
                    className={` w-4 h-4  rounded-full shadow-md transform duration-300 ease-in-out ${
                      isToggledTodayOverAll ? "translate-x-7" : "translate-x-1"
                    }`}
                  />
                </button>
              </div>
              <div className="mr-[21px]">
                <span
                  style={{ fontSize: 12, fontWeight: "500", color: "#817E7E" }}
                >
                  Overall
                </span>
              </div>
            </div>

            <div className="mr-[16PX]">
              <button>
                <img
                  src={DownloadIcon}
                  alt="download"
                  style={{ width: 14, height: 16 }}
                />
              </button>
            </div>
          </div>

          <table className="min-w-full bg-white orderTable">
            <thead className="orderTable">
              <tr>
                <th className="px-4 py-2">
                  <img
                    src={selectIcon}
                    alt="select option"
                    style={{ width: 16, height: 16 }}
                  />
                </th>
                {/* <th className="px-4 py-2 border">Type</th>
                <th className="px-4 py-2 border">Instrument</th>
                <th className="px-4 py-2 border">Buy Qty.</th>
                <th className="px-4 py-2 border">Sell Qty.</th>
                <th className="px-4 py-2 border">Buy Avg</th>
                <th className="px-4 py-2 border">Sell Avg</th>
                <th className="px-4 py-2 border">PNL</th>
                <th className="px-4 py-2 border">LTP</th> */}
                <th className="px-4 py-2 border">Type</th>
                <th className="px-4 py-2 border">Instrument</th>
                <th className="px-4 py-2 border">Buy Qty.</th>
                <th className="px-4 py-2 border">Sell Qty.</th>
                <th className="px-4 py-2 border">Buy Avg</th>
                <th className="px-4 py-2 border">Sell Avg</th>
                <th className="px-4 py-2 border">PNL</th>
                <th className="px-4 py-2 border">Ltp</th>
                {/* <th className="px-4 py-2 border">Net</th>
                <th className="px-4 py-2 border">Action</th> */}
              </tr>
            </thead>
            <tbody>
              {positions?.map((position, index) => {
                // console.log("positions : ", position);
                const Ltp = position?.last_traded_price;
                const BuyAT = position?.buy_qty * position?.buy_avg;
                const SELLAT = position?.sell_qty * position?.sell_avg;
                const old_profit = BuyAT - SELLAT;
                const NbuyAt = position?.buy_qty * Ltp;
                const NSellAt = position?.sell_qty * Ltp;
                const New_Profit = NbuyAt - NSellAt;
                const PNL = New_Profit - old_profit;
                return (
                  <tr key={index}>
                    <td className="px-4 py-2 border orderTabletd">
                      <img
                        src={selectIcon}
                        alt="select option"
                        style={{ width: 16, height: 16 }}
                      />
                    </td>
                    <td className="px-4 py-2 border orderTabletd">
                      <button
                        style={{
                          fontSize: 10,
                          paddingLeft: 4,
                          paddingRight: 4,
                          height: 20,
                          borderRadius: 3,
                          color: positionType(position.ptype).color,
                          backgroundColor: positionType(position.ptype)
                            .backgroundColor,
                        }}
                      >
                        {/* {`${position.ptype}  `} */}
                        {positionType(position.ptype).type}
                      </button>
                    </td>

                    <td
                      className="px-4 py-2 border relative orderTabletd"
                      style={{
                        fontSize: 14,
                        color: "#363636",
                        fontWeight: "700",
                      }}
                      onMouseOver={() => {
                        onMouseCallOverPositionInstrument(index);
                      }}
                      onMouseOut={() => {
                        onMouseCallOutPositionInstrument(index);
                      }}
                    >
                      <div className="relative">
                        <div>{position.instrument__trading_symbol}</div>

                        {position.isAddOrExitBtnOpen && (
                          <div className="addorexit">
                            <button
                              className="addBtn"
                              onClick={() =>
                                onOpenAddModal(
                                  position.buy_qty,
                                  position.sell_qty,
                                  position.instrument__trading_symbol,
                                  position.instrument__exchange_token,
                                  position.instrument__exchange,
                                  position.last_traded_price
                                )
                              }
                            >
                              <p>Add</p>
                            </button>
                            <button
                              className="exitBtn"
                              onClick={() =>
                                onOpenExitModal(
                                  position.buy_qty,
                                  position.sell_qty,
                                  position.instrument__trading_symbol,
                                  position.instrument__exchange_token,
                                  position.instrument__exchange,
                                  position.last_traded_price
                                )
                              }
                            >
                              <p>Exit</p>
                            </button>
                          </div>
                        )}
                      </div>
                    </td>
                    <td className="px-4 py-2 border orderTabletd">
                      {position.buy_qty}
                    </td>
                    <td className="px-4 py-2 border orderTabletd">
                      {position.sell_qty}
                    </td>
                    <td className="px-4 py-2 border orderTabletd">
                      {position.buy_avg}
                    </td>
                    <td className="px-4 py-2 border orderTabletd">
                      {position.sell_avg}
                    </td>
                    <td
                      className="px-4 py-2 border orderTabletd"
                      style={{
                        color: PNL >= 0 ? "#39A245" : "#DF2123",
                      }}
                    >
                      {/* {position.LTP} */}
                      {isNaN(PNL) ? 0 : PNL.toFixed(2)}
                    </td>
                    <td
                      className="px-4 py-2 border orderTabletd"
                      style={{
                        color: Ltp >= 0 ? "#39A245" : "#DF2123",
                      }}
                    >
                      {/* {position.PL} */}
                      {isNaN(Ltp) ? 0.0 : Ltp.toFixed(2)}
                    </td>
                    {/* <td
                    className="px-4 py-2 border orderTabletd"
                    // style={{
                    //   color:
                    //     parseInt(position.Netchg) > 0 ? "#39A245" : "#DF2123",
                    // }}
                  >
                    {position?.Netchg}
                  </td> */}
                  </tr>
                );
              })}
              <tr>
                <td className="px-4 py-2 border orderTabletd" colSpan="6"></td>
                <td className="px-4 py-2  orderTabletd">Total</td>
                <td className="px-4 py-2  orderTabletd">
                  {isNaN(totalPNL) ? 0 : totalPNL.toFixed(2)}
                </td>
                <td className="px-4 py-2  orderTabletd">
                  {isNaN(totalLTP) ? 0 : totalLTP.toFixed(2)}
                </td>
                <td className="px-4 py-2  orderTabletd"></td>
              </tr>
            </tbody>
          </table>
        </div>
      )}
      {/* <BuyAndSellModal /> */}
    </Layout>
  );
}

export default Positions;
